#trial_les_form_id {
    .select2 {
        grid-row: 1;
        min-width: 365px;
        box-sizing: border-box;

        @include mq(1550) {
            min-width: 258px;
        }

        @include mq(992) {
            min-width: 180px;
        }

        @include mq(785) {
            min-width: 277.72px;
            margin-left: 0;
            
            span {
                margin-right: 0;
                margin-left: 0;
            }
        }

        @include mq(510) {
            min-width: 100%;
        }

        &-container {


            &--default {
                .select2-selection--single {
                    background-color: transparent;
                    width: 100%;
                    height: 100%;
                    border: 1px solid rgba(255,255,255,.5);
                    border-radius: 27.5px;
                    background-color: transparent;
                    padding: 2rem 4.5rem;
                    font-size: 1.6rem;
                    box-sizing: border-box;
                    -webkit-transition: all .25s;
                    transition: all .25s;
                    height: 60px;

                    @include mq(1550) {
                        height: 48px;
                    }

                    @include mq(1180) {
                        height: 41.27px;
                    }

                    @include mq(992) {
                        height: 36.47px;
                    }

                    @include mq(785) {
                        height: 44px;
                        padding: 2.7rem 3.7rem;
                    }

                    @include mq(510) {
                        border-radius: 0px;
                        padding: 2.4rem 2.6rem;
                    }
                   
                    .select2-selection__rendered {
                        line-height: normal;
                        color: #fff;
                        font-size: 1.6rem;
                        font-family: Roboto;
                        font-weight: 200;


                        @include mq(785) {
                            margin-bottom: 5px;
                        }

                        @include mq(510) {
                            height: auto;
                            font-size: 14px;
                        }
                    }

                    .select2-selection__placeholder {
                        color: rgba(#fff,0.5);
                    }

                    .select2-selection__arrow {
                        top: 50%;
                        transform: translateY(-55%) rotate(-45deg);
                        right: 20px;
                        transition: all .3s ease-in-out;
                        b {
                            width: 8px;
                            height: 8px;
                            border: none;
                            border-bottom: 1px solid rgba(#fff,0.5);
                            border-left: 1px solid rgba(#fff,0.5);
                        }

                        @include mq(1180) {
                            ransform: translateY(-58%) rotate(-45deg);
                        }

                        @include mq(785) {
                            top: 38%;
                        }

                        @include mq(510) {
                            top: 44%;
                        }
                    }


                    &:focus,&:active {
                        outline: 0px;
                    }
                }

                .select2-results>.select2-results__options {
                    background-color: #fff;

                    .select2-results__option {
                        color: black;
                        font-size: rem(20);
                        line-height: rem(28);
                        padding-left: 10px;

                        &:hover {
                            background-color: #ffc40c;
                        }

                        &--highlighted {
                            background-color: #f5f5f5;
                        }
                    }
                }
            }
        }

        &-container--open {
            .select2-selection--single {
                border-color: #fff;

                .select2-selection__arrow {
                    transform: translateY(-46%) rotate(-225deg);
                }
            }
        }
    }
}

#trial_les_form_id form p input[type=submit] {
    @include mq(992) {
        grid-row: 2;
    }
}