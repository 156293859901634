.text-yellow {
    color: $yellow;
}

.pr-0 {
    padding-right: 0;
}

.pl-0 {
    padding-left: 0;
}

.pr-xs-0 {
    @include mq(992) {
        padding-right: 0 !important;
    }
}

.pl-xs-0 {
    @include mq(992) {
        padding-left: 0 !important;
    }
}


.fat {
    display: flex;
    flex-basis: 100%;
    flex-grow: 1;
    flex-shrink: 1;
}

.fat-row {
    display: flex;
    flex-basis: 1%;
    flex-grow: 1;
    flex-shrink: 1;
}

.no-mobile {
    @include mq(992) {
        display: none;
    }
}

.no-desctop {
    @media only screen and (min-width: 993px) {
        display: none;
    }
}


.pos-relative {
    position: relative;
}

@media (min-width: 1600px) {
    .container {
        max-width: 1380px;
    }
}
