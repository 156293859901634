#contacts,.studioSubsection__footer--order {
    .form-row {
        display: flex;
        align-items: center;
        position: relative;

        .form-square-select {
            span[role="alert"] {
                top: -46px;
            }
        }

        @include mq(576) {
            flex-direction: column;
            align-items: flex-start;

            &>span {
                width: 100%;
            }

            input {
                width: 100%;
                max-width: 100% !important;
            }

            .form-square-select {
                margin-top: 20px;
                margin-left: 0;
                width: 100%;

                .select2 {
                    min-width: 100%;
                }
            }
        }


    }

    .select2 {
        grid-row: 1;
        min-width: 365px;
        box-sizing: border-box;

        @include mq(992) {
            min-width: 290px;
        }

        @include mq(785) {
            min-width: 100%;
        }

        @include mq(650) {
            min-width: 100%;
        }

        &-container {


            &--default {
                .select2-selection--single {
                    background-color: transparent;
                    width: 100%;
                    height: 100%;
                    border: 1px solid rgba(33,33,33,.5);
                    background-color: transparent;
                    padding: 18px 30px;
                    font-size: 1.6rem;
                    box-sizing: border-box;
                    -webkit-transition: all .25s;
                    transition: all .25s;
                    height: 55px;
                   
                    .select2-selection__rendered {
                        line-height: normal;
                        color: #2c2c2c;
                        font-size: 16px;
                        font-family: Roboto;
                        font-weight: 400;
                    }

                    .select2-selection__placeholder {
                        color: rgba(#2c2c2c,0.5);
                    }

                    .select2-selection__arrow {
                        top: 50%;
                        transform: translateY(-60%) rotate(-45deg);
                        right: 20px;
                        transition: all .3s ease-in-out;
                        b {
                            width: 8px;
                            height: 8px;
                            border: none;
                            border-bottom: 1px solid rgba(#2c2c2c,0.5);
                            border-left: 1px solid rgba(#2c2c2c,0.5);
                        }
                    }


                    &:focus,&:active {
                        outline: 0px;
                    }
                }

                .select2-results>.select2-results__options {
                    background-color: #fff;

                    .select2-results__option {
                        color: black;
                        font-size: rem(20);
                        line-height: rem(28);
                        padding-left: 10px;

                        &:hover {
                            background-color: rgb(136, 88, 160);
                        }

                        &--highlighted {
                            background-color: #f5f5f5;
                        }
                    }
                }
            }
        }

        &-container--open {
            .select2-selection--single {
                border: 1px solid #2c2c2c;

                .select2-selection__arrow {
                    transform: translateY(-46%) rotate(-225deg);
                }
            }
        }
    }
}

#contacts {
    .form-square-select {
        padding-top: 8px;

    }
}

.studioSubsection__footer--order {
    .form-square-select {
        margin-left: 25px;
    }
}