.contacts__itemTitle {
    font-family: "Lora", sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 35px;
    margin-top: 50px;
}

.contacts__inform {
    grid-column: 1/span 6;
}

.contacts__subscribe {
    grid-column: 7/span 4;
}

.contacts__social {
    grid-column: 11/span 1;
    justify-self: end;
}

.contacts__items {
    display: flex;

    @include mq(992) {
        flex-direction: column;
    }
}

.contacts__contactsItemWrapper {
    display: flex;
    color: rgba(255, 255, 255, 0.8);
    line-height: 28px;

    &:not(:last-of-type) {
        margin-right: 40px;

        @include mq(992) {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
}

.contacts__inform .contacts__contactsItemWrapper {
    font-size: 1.6rem;
}

.contacts__icon {
    margin-right: 18px;

    svg {
        width: 28px;
    }
}

.contacts__address {
    position: relative;
    opacity: 0.6;	
    color: #FFFFFF;	
    font-family: Roboto;	
    font-size: rem(14);	
    line-height: rem(14);
    margin-top: 5px;

    &_small {
        font-size: rem(10);	

        @include mq(992) {
            font-size: 8px;
        }
    }

    @include mq(992) {
        font-size: 12px;
        line-height: 11px;
    }
}

.contacts__phones {
    display: flex;
    flex-direction: column;
    position: relative;
    color: #FFFFFF;	
    font-family: Roboto;	
    font-size: rem(22);	
    font-weight: 500;	
    line-height: rem(24);
    white-space: nowrap;

    @include mq(992) {
        font-size: 18px;
        line-height: 22px;
    }

}

.contacts__iconWrapper {
    height: 55px;
    width: 55px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    margin-right: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.contacts__iconWrapper img {
    height: 100%;
    width: 100%;
}