.header__nav.mobile-menu-visible {
    display: flex;
    transform: translateX(0);
}

.header__nav.mobile-menu-visible .header__listItem.header__dropdownToggle .shevrone_icon {
    height: 24px;
    margin-left: 20px;
}

#header.hide {
    transform: translateY(-100%);
}


.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 3;
    min-height: 100px;
    height: auto;
    background-color: #F5F5F5;
    color: #000;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    transition: transform .5s linear;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15);

    @include mq(920) {
        min-height: auto;
    }


    &__phones {
        display: flex;
        margin-left: 64px;
        align-items: center;
        justify-content: flex-end;
        min-height: 78px;
        margin-right: 0;
        padding-right: 15px;

        @include mq(1400) {
            margin-left: 30px;
        }

        @include mq(1365) {
            min-height: 50px;
            align-items: flex-end;
            padding-bottom: 5px;
        }

        @include mq(920) {
            position: absolute;
            flex-direction: column;
            top: 57px;
            right: 63px;
            background-color: #fff;
            justify-content: center;
            margin-left: 0;
            min-height: auto;
            padding: 0;
            border-radius: 4px;
            box-shadow: 0 10px 40px 0 rgba(0,0,0,.35);
            width: auto;
            z-index: 3;

            &::after {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                background-color: #fff;
                position: absolute;
                transform: rotate(45deg);
                top: -2px;
                right: 20px;
            }

            &.closed {
                display: none;
            }
        }

        &-mobile {
            display: none;
            width: 73px;
            height: 73px;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 73px;

            &-icon {
                fill: #8858A0;
            }

            .shevrone_icon {
                width: 16px;
                height: 16px;
                transform: rotate(90deg);
                transition: all .3s ease-in-out;
            }

            &:hover {
                cursor: pointer;
            }

            &.opened {
                .shevrone_icon {
                    transform: rotate(-90deg);
                }
            }

            @include mq(920) {
                display: flex;
            }
        }
    }

    &__nav {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
        margin-left: 50px;

        @include mq(920) {
            margin-left: 0;
        }
    }

    &__phone-block {
        display: flex;

        &:first-of-type {
            margin-right: 42px;

            @include mq(920) {
                margin-right: 0;
            }
        }

        svg {
            margin-right: 14px;
        }

        @include mq(920) {
            &:not(:last-of-type) {
                border-bottom: 1px solid rgba(#000,0.15);
            }
            width: 100%;
            padding: 8px 20px;
        }
    }

    &__right {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-end;

        @include mq(920) {
            width: auto;
        }
    }

    &__phone {
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: rem(20);
        margin-bottom: 5px;
        font-weight: bold;
        color: #000;
    }

    &__address {
        font-size: rem(12);
        color: rgba(#000,.6);

        @include mq(1200) {
            font-size: rem(14);
        }

        @include mq(992) {
            font-size: rem(16);
        }
    }

    &__list {
        display: flex;
        flex-wrap: nowrap;
        height: 100%;
        align-items: center;
    }

    &__listItem {
        height: 100%;
        padding-right: 15px;
        padding-left: 15px;
        border-top: 4px solid transparent;
        cursor: pointer;
        position: relative;
        transition: .15s;
        min-height: 65px;

        .shevrone_icon {
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
            height: 15px;
        }

        &.menu_text_title {
            display: none;
            color: rgba(136, 88, 160, 1);
            font-size: 22px;
            font-weight: bold;
            font-family: 'Lora', sans-serif;
            margin-bottom: 36px;

            @include mq(920) {
                display: block;
            }
        }

        &.active,
        &:hover {
            border-top: 4px solid #8858A0;
            background-color: #e7e7e7
        }

        @include mq(1475) {
            padding-left: 10px;
            padding-right: 10px;
        }

        @include mq(920) {
            min-height: auto;
        }
    }

    &__link {
        display: flex;
        height: 100%;
        align-items: center;
        font-size: 18px;
        white-space: nowrap;

        @include mq(1612) {
            font-size: 16px;
        }
    }

    &__logoWrapper {
        display: flex;
        align-items: center;
    }
    
    &__logo {
        max-width: 999px;
        border-radius: 50%;
    }
}
