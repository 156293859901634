html {
    height: 100%;
    font-family: "Roboto", sans-serif;
    font-size: 62.5%;
}

body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    overflow-x: hidden;
}

a,
a:hover,
a:active {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

ul {
    list-style-type: none;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
    padding: 0;
    margin: 0;
}

.container {
    max-width: 1450px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.container-small {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.container-grid {
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: auto;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
}