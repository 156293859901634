.contacts-separate {
    margin-top: 100px;
    padding-bottom: 8rem;
}

.contacts-separate__title {
    text-align: center;
    font-family: "Lora", sans-serif;
    color: #212121;
    font-size: 5rem;
    padding-top: 15rem;
    padding-bottom: 10rem;

    @include mq(576) {
        padding-top: 65px;
        padding-bottom: 50px;
    }
}

.contacts-separate__cards-wrapper {
    display: flex;
    margin-bottom: 280px;

    @include mq(920) {
        flex-direction: column;
    }
}

.contacts-separate__section-wrapper {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    
    padding: 60px 120px;

    &:last-of-type {
        background-color: #f5f5f5;
    }

    @include mq(1365) {
        padding: 40px 80px;
    }

    @include mq(1023) {
        padding: 30px 65px;
    }

    @include mq(920) {
        padding: 10px 15px;
    }
}

.contacts-separate__header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    margin-left: -50px;

    &-icon {
        margin-right: 15px;
    }

    &-text {
        color: #212121;
        font-size: rem(26);
        line-height: rem(39);
        font-family: Lora,Roboto;
        font-weight: bold;
    }

    @include mq(920) {
        margin-left: 0;
        margin-bottom: 25px;
    }
}

.contacts-separate__section {
    display: flex;
    margin-bottom: 25px; 

    &_schedule {
        flex-direction: column;
        margin-left: 10px;
    }

    @include mq(920) {
        margin-bottom: 10px;
    }
}

.contacts-separate__section--title {
    opacity: 0.8;
    font-size: 14px;
    line-height: 2.8rem;
    margin-bottom: 2rem;
    position: relative;
}

.contacts-separate__section--title svg,
.contacts-separate__section--title svg path {
    fill: #8858A0;
    margin-right: 0.8rem;
    height: 36px;
    width: 36px;

}

.contacts-separate__section--link {
    color: #2C2C2C;
    font-size: rem(18);
    line-height: rem(28);
    font-family: Roboto;
    padding-top: 4px;

    @include mq(920) {
        font-size: 14px;
        line-height: 28px;
    }
}

.contacts-separate__section--helper-wrapper {
    display: flex;
    flex-direction: column;
}

.contacts-separate__section--inform-location {
    opacity: 0.8;
    color: #888888;
    font-size: 1.4rem;
    line-height: 2.8rem;
    margin-top: 0.1rem;
}

.schedule {
    background: #8858A0;
    padding-top: 7rem;
    padding-bottom: 35rem;
}

.schedule__sections--wrapper {
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.schedule__sections--day {
    color: #2c2c2c;
    font-size: rem(18);
    line-height: rem(28);
    font-weight: 100;

    @include mq(920) {
        font-size: 14px;
        line-height: 28px;
    }
}

.schedule__sections-hours {
    color: #2c2c2c;
    font-size: rem(22.4);
    line-height: rem(26);
    font-weight: bold;
    display: flex;

    @include mq(920) {
        font-size: 16px;
        line-height: 19px;
    }
}

.schedule__sections-hours span {
    font-size: rem(9.6);
    line-height: rem(11);
}

.schedule__sections {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    
    &:not(:last-of-type) {
        border-bottom: 1px solid rgba(#000,0.15);
    }
}

#schedule-price-section {
    margin-top: 100px;
}

.schedule-price-section__header {
    background: #8858A0;
    color: #fff;
    padding-bottom: 6rem;
}

.schedule-price-section__title {
    font-size: 5rem;
    font-family: "Lora", sans-serif;
    font-weight: bold;
    text-align: center;
    padding-top: 10rem;
    padding-bottom: 10rem;
}