.studioSubsection {
    margin-top: 100px;

    &__header {
        position: relative;

        &--content {
            grid-column: 2/span 6;
            padding-bottom: 11.1rem;
            color: #fff;
        }
    }

    &__imageBg {
        position: absolute;
        height: 100%;
        width: 40%;
        right: 0;
        object-fit: cover;
    }

    &__title {
        font-family: "Lora", sans-serif;
        font-size: 5rem;
        font-weight: bold;
        margin-bottom: 3rem;
    }

    &__description {
        opacity: 0.8;
        font-size: 1.6rem;
        line-height: 2.8rem;
        margin-bottom: 4.5rem;
        max-width: 706px;
    }

    &__blockWrapper {
        display: flex;
        margin-bottom: 6.5rem;

        @include mq(400) {
            flex-wrap: wrap-reverse;
        }
    }

    &__age,&__price,&__office {
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 3px;
        padding: 2.5rem;
        padding-right: 5rem;
        margin-right: 3rem;

        &--title {
            font-weight: 500;
            font-size: 1.6rem;
            margin-bottom: 4.5rem;
        }

        &--text,&--currency {
            font-size: 1.8rem;
            margin-left: 0.5rem;
        }

        &--ageCount,&--priceCount {
            font-size: 4.2rem;
            font-weight: 300;
            display: flex;
            align-items: baseline;
        }
    }

    &__office {
        margin-right: 0;
        
        &--title {
            @include mq(400) {
                white-space: normal !important;
            }
        }

        &--content {
            svg:not(:last-of-type) {
                margin-right: 10px;

                @include mq(920) {
                   margin-right: 5px;
                }
            }

            svg {
                @include mq(920) {
                    width: 20px;
                }
            }
        }

        @include mq(920) {
            padding-right: 2.5rem;
            
        }

        @include mq(400) {
            margin-bottom: 20px;
        }
    }

    &__price {
        @include mq(400) {
            margin-bottom: 20px;
        }
    }

    &__button {
        background-color: #FFC40C;
        color: #212121;
    }

    &__body {
        padding-top: 4.5rem;
        padding-bottom: 85px;
        -ms-grid-column-span: 7;
        -ms-grid-column: 2;
        grid-column: 2/span 7;
        color: #2C2C2C;
        font-size: 15px;
        line-height: 1.7;

        a {
            color: #8858A0;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}


.studioSubsection__body ul,
.blogArticle__content ol {
    padding-left: 4rem;
}

.studioSubsection__body li {
    position: relative;
    font-size: 15px;
}

.studioSubsection__body li::before {
    content: "•";
    color: rgba(136, 88, 160, 1);
    position: absolute;
    font-size: 3rem;
    left: -1.5rem;
    top: -5px;
}