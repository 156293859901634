$education: linear-gradient(90deg, #5ACA14 0%, #4CB00C 100%);
$art: linear-gradient(90deg, #00B0FF 0%, #0199DD 100%);
$preschool: linear-gradient(90deg, #FA5129 0%, #E04722 100%);
$individual: linear-gradient(90deg, #F18C0C 0%, #DA800C 100%);
$courses: linear-gradient(90deg, #F18C0C 0%, #DA800C 100%);
$costumes: linear-gradient(90deg, #07C394 0%, #06B086 100%);

.schedule-price-section {
    &__metagroup {
        display: flex;
        width: 100%;
        border-radius: 4px 0 0 4px;

        &:not(:last-of-type) {
            margin-bottom: 25px;
        }

        &.education .metagroup__name {
            background: linear-gradient(90deg, #5ACA14 0%, #4CB00C 100%);
        }

        &.art .metagroup__name {
            background: linear-gradient(90deg, #00B0FF 0%, #0199DD 100%);
        }

        &.preschool .metagroup__name {
            background: linear-gradient(90deg, #FA5129 0%, #E04722 100%);
        }

        &.individual .metagroup__name {
            background: linear-gradient(90deg, #F18C0C 0%, #DA800C 100%);
        }

        &.courses .metagroup__name {
            background: linear-gradient(90deg, #F18C0C 0%, #DA800C 100%);
        }

        &.costumes .metagroup__name {
            background: linear-gradient(90deg, #07C394 0%, #06B086 100%);
        }

        @include mq(1280) {
            flex-direction: column;       
        }
    }
}


.metagroup {
    &__name {
        min-width: 168px;
        font-family: Roboto;	
        font-size: 40px;	
        font-weight: bold;	
        line-height: 47px;	
        text-align: center;
        color: #fff;
        padding: 44px 0;
        border-radius: 4px 0 0 4px;
        display: flex;
        flex-direction: column;

        @include mq(1280) {
            border-radius: 4px 4px 0 0;
        }
    }

    &__content {
        display: flex;
        flex-shrink: 1;
        flex-grow: 1;
        width: 100%;
        flex-direction: column;
        padding-left: 74px;
        background: #FAFAFA;
        border: 1px solid rgba(#000,0.15);
        border-left: none;

        @include mq(1280) {
            padding-left: 0;
        }
    }

    &__header {
        display: flex;
        border-bottom: 1px solid rgba(#000,0.15);
        padding-top: 30px;

        @include mq(1280) {
            display: none;
        }

        &_mobile {
            display: none;

            @include mq(1280) {
                display: flex;
                flex-direction: column;
                padding-top: 0;
                min-width: 250px;
                border-bottom: none;
            }

            @include mq(650) {
                min-width: 100px;
            }
        }
    }

    &__span {
        &_big {

            text-align: center;
        }

        &_medium {
            font-size: rem(28);
            line-height: rem(63);
        }

        &_small {
            font-size: 14px;	
            line-height: 16px;
            font-weight: normal;
        }
    }


}

.schedule-price-section__groupe {
    a {
        display: block;
        transition: color .3s ease-in;
        text-align: left;
        padding-left: 0;
        margin-left: 0;
        font-size: rem(34);
        line-height: rem(43);

        &:hover {
            color: #8858A0;
        }
    }

    &--transition-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 0;
        margin-left: 0;

 
    }

    &--item {
        width: 100%;
        margin-right: 15px;
        border-radius: 0 4px 4px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        &:not(:last-of-type) {
            border-bottom: 1px solid rgba(#000,0.15);

        }

        @include mq(1280) {
            align-items: flex-start;
            padding: 0 20px;
            padding-bottom: 23px;
            border-left: 1px solid rgba(#000,0.15);
        }
    }

    &--title {
        font-size: 3.4em;
        font-family: "Lora", sans-serif;
        color: #2C2C2C;
        font-weight: bold;
        padding-top: 5rem;
        padding-bottom: 3rem;
        text-align: center;
    }

    &:first-child {
        .schedule-price-section__groupe--title {
            padding-top: 6rem;
        }
    }
}

.schedule {
    &-content {
        display: flex;
        flex-direction: row;
        width: 100%;

        @include mq(1280) {
            flex-direction: column;
        }
    }

    &-wrapper {
        margin: rem(24) 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg, svg path {
            height: 20px;
            width: 20px;
            margin-top: -3px;
        }

        &.office-wrapper {
            flex-shrink: 2;
            min-width: 30px;
        }

        @include mq(1280) {
            height: 52px;
            margin-top: 0;
            margin-bottom: 0;
            
            &:not(:last-of-type) {
                border-bottom: 1px solid rgba(#000,0.15);
            }

            &.office-wrapper {
                border-bottom: none;
                padding-top: 10px;
                margin-bottom: 20px;
            }

            &.button-wrapper {
                align-items: flex-end;
            }
        }
    }

    &__title {
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 1);
        font-size: rem(14);
        line-height: rem(16);
        font-weight: 500;
        margin-bottom: 10px;

        svg {
            margin-right: 5px;
            opacity: .5;
        }

        &.office-title {
            flex-shrink: 2;
        }

        @include mq(1280) {
            font-size: 16px;
            line-height: 18px;
        }
        @include mq(800) {
            font-size: 14px;
            line-height: 16px;
        }
        @include mq(500) {
            font-size: 12px;
            line-height: 14px;
        }
    }

    &__points {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: baseline;
        font-size: rem(28);
        line-height: rem(33);

        @include mq(1280) {
            justify-content: flex-end;
        }

        @include mq(514) {
            font-size: 16px;
        }
    }

    &__column {
        width: 100%;
        display: flex;
        // justify-content: center;

        svg {
            max-width: 24px;
        }

        @include mq(1280) {
            height: 52px;
            margin-bottom: 0;

            &:not(:last-of-type) {
                border-bottom: 1px solid rgba(#000,0.15);
            }

            &.office-title {
                border-bottom: none;
            }
        }
    }

    &-button.order {
        background: #E8E8E8;
        box-shadow: none;
        color: #212121;
        font-size: rem(17);
        border: 1px solid rgba(#000,0.15);

        &:hover {
            background: #8858A0;
            color: #fff
        }

        @include mq(1280) {
            background: #ffc40c;
            border-color: #ffc40c;
            width: 312px;
            font-size: 15px;
            line-height: 11px;
        }

        @include mq(514) {
            width: 270px;
        }

        @include mq(460) {
            width: 180px;
        }
    }
}

.order {
    color: rgba(33, 33, 33, 1);
    background: #f0f0f0;
    transition: all .1s ease;
    box-shadow: none;
    border: 1px solid #dcdcdc;
}

.order:hover {
 
}

.time-wrapper {
    display: flex;
    margin-right: 12px;
    margin-bottom: 0.5rem;

    &:first-of-type {
        margin-left: 0;
    }

    &:last-of-type {
        margin-right: 0;
    }

    .mins {
        color: #424242;
        font-size: rem(13);
        line-height: rem(15);
        text-decoration: underline;
        margin-left: 3px;
    }

    .coma {
        display: inline-block;
        margin-left: 2px;
    }
}

.price-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: #424242;

    &:not(:last-of-type) {
        margin-right: 50px;

        @include mq(1280) {
            margin-right: 28px;
        }
    }

    .price {
        font-size: rem(28);
        font-weight: 900;

        @include mq(514) {
            font-size: 16px;
        }
    }

    .desc {
        color: rgba(0, 0, 0, .5);
        font-size: rem(14);
        line-height: rem(16);

        @include mq(514) {
            font-size: 10px;
        }
    }

    &:nth-child(even) {
        .price {
            font-weight: normal;
        }
    }
}

.days-connection {
    color: rgba(66, 66, 66, .8);
    font-size: 1.8rem;
    font-weight: 300;
    margin-right: 5px;
    margin-left: 5px;
}


.schedule__points--price {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-right: -3rem;
    margin-left: -3rem;

    @include mq(1280) {
        justify-content: flex-end;
    }
}

.schedule__points--office {
    .office-wrapper {
        display: flex;
        justify-content: center;
    }

    .office-logo {
        svg {
            height: 38px;
            width: 28px;
        }

        @include mq(1280) {
            margin-right: 10px;
        }
    }

    .office-address {
        &_mobile {
            display: none;

            @include mq(1280) {
                display: flex;
                font-size: 10px;	
                line-height: 6px;
            }
        }
    }

    @include mq(1280) {
        align-items: center;
    }
}


.office-select.v-select.single {
    min-width: 320px;
    padding-top: 40px;


    .dropdown-toggle {
        border: none;
        border-radius: 0px;
        border-bottom: 2px solid #fff;
    }

    .selected-tag {
        color: #fff;
        font-size: rem(21);
        line-height: rem(24);
        font-weight: 500;
        font-family: Roboto;
    }

    .clear {
        display: none;
    }

    .open-indicator {
        &::before {
            border-color: #fff;
        }
    }

    .dropdown-menu {
        overflow: hidden;
        background: #fff;
        font-size: 18px;
        font-weight: normal;
        font-family: Roboto;
        border-radius: 0px;
        padding-bottom: 0;
        padding-top: 0;
        border: none;

        li {
            a {
                padding: 10px 25px;
                display: flex;
                align-items: center;
                line-height: 18px;
                padding-left: 0;

                &:hover {
                    background-color: #ffc40c;
                    color: #000;
                }
            }

            &.active {
                a {
                    background-color: rgba(0,0,0,0.15);
                    color: #000;
                }
            } 
            
            &.highlight {
                a {
                    background-color: #ffc40c;
                    color: #000;
                }
            }
        }
    }

    &.open {
        .selected-tag {
            opacity: 1;
            position: relative;
        }

        .dropdown-toggle {
            border-color: transparent;
        }
    }

    .option {
        display: flex;
        align-items: center;
        &__icon {
            width: 15px;
            margin-right: 10px;
            margin-left: 10px;

            &_noicon {
                width: 35px;
            }
        }
    }

    @include mq(1365) {
        padding-top: 25px;
    }

    @include mq(1200) {
        padding-top: 25px;
    }


    @include mq(992) {
        padding-top: 20px;
        min-width: 220px;
    }

    @include mq(680) {
        min-width: 150px;
    }
}

.schedule-price-section__body {
    padding-bottom: 60px;

    @include mq(992) {
        padding-bottom: 250px;
    }
}

.schedule-price-section__subtitle {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 5rem;
    text-align: flex-start;

    @include mq(840) {
        margin-bottom: 10px;
    }
}

.schedule-price-section__content_small {
    .schedule-price-section__subtitle {
        @include mq(610) {
            display: none;
        }
    }
}

.filter-list {
    &.open {
        @include mq(610) {
            padding-top: 20px;
        }
    }
}

.filter-item {
    label {
        width: 100%;
        height: 100%;
        text-align: center;
        cursor: pointer;
        position: absolute;
        font-size: rem(24);
        line-height: rem(28);

        &::after {
            content: '';
            display: block;
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
            border: 3px solid rgba(255, 255, 255, .3);
            background: #8858A0;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);


            @include mq(610) {
                left: 3rem;
            }
        }

        @include mq(610) {
            position: static;
            text-align: left;
        }
    }

    #schedule-price__all, #schedule-price__old {
        & + label {
            font-size: rem(21);
            line-height: rem(24);
        }
    }

    @include mq(610) {
        flex-direction: row;
    }
}

.filter-item img {
    opacity: .3;
    height: 100%;
}

.filter-item input {
    cursor: pointer;
    opacity: 0;
}


.filter-item input[type=checkbox]:checked+label::after {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
    border: 3px solid #FFFFFF;
    background-color: #FFC40C;
}

.filter-item.checked label::after {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
    border: 3px solid #FFFFFF;
    background-color: #FFC40C;
}

.filter-item input[type=checkbox]:checked+label {
    color: rgba(255, 255, 255, 1);
}

.filter-item:hover {
    color: rgba(255, 255, 255, 1);
}

.filter-item:hover label::after {
    border-color: rgba(255, 255, 255, 1);
}

.filter.stick {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    padding-bottom: 10px;
    padding-top: 10px;
    background: #8858A0;
    z-index: 1;
    padding-left: 24vw;
    padding-right: 24vw;
    transition: top .5s linear;
    width: 100%;
    max-width: 100%;
    padding: 7px 15% !important;

    .schedule-price-section__subtitle {
        display: none;
    }

    .filter-list {
        height: 6rem;
    }

    .office-select {
        padding-top: 15px;
    }
}

.filter.stick label {
    font-size: 18px;
}

.filter.stick label::after {
    height: 20px;
    width: 20px;
}

.filter.stick.offsetTop {
    top: 100px;
}

.schedule-price-section__content {
    &_large {
        grid-column: 1/9;
    }

    &_small {
        grid-column: 10/-1;
    }

    @include mq(1550) {
        &_large {
            margin-left: 15px;
        }
    
        &_small {
            margin-right: 15px;
        }
    }

    @include mq(1365) {
        &_large {
            grid-column: 3/8;
            margin-left: 0;
        }
    
        &_small {
            grid-column: 9/10;
            margin-right: 0;
        }
    }

    @include mq(1200) {
        &_large {
            grid-column: 2/8;
        }
    
        &_small {
            grid-column: 9/11;
        }
    }

    @include mq(992) {
        &_large {
            grid-column: 1/8;
            margin-left: 15px;
        }
    
        &_small {
            grid-column: 9/-1;
            margin-right: 15px;
        }
    }

    @include mq(840) {
        &_large {
            grid-column: 1/-1;
            margin-left: 15px;
            margin-right: 15px;
        }
    
        &_small {
            grid-column: 1/7;
            margin-right: 0;
            margin-left: 15px;
            margin-top: 50px;
        }
    }

    @include mq(670) {
        &_small {
            grid-column: 1/8;
        }
    }

    @include mq(610) {
        &_large {
            grid-column: 2/-2;
            margin-left: 15px;
            margin-right: 15px;
        }
    
        &_small {
            grid-column: 2/-2;
            margin-right: 15px;
            margin-left: 15px;
            margin-top: 0px;
        }
    }
}

.schedule-price-section__body {
    @include mq(1550) {
        .container {
            max-width: 100%;
        }
    }
}