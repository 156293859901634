@charset "UTF-8";
@import url(libs/select2.css);
.text-yellow {
  color: #DAAD4E; }

.pr-0 {
  padding-right: 0; }

.pl-0 {
  padding-left: 0; }

@media only screen and (max-width: 992px) {
  .pr-xs-0 {
    padding-right: 0 !important; } }

@media only screen and (max-width: 992px) {
  .pl-xs-0 {
    padding-left: 0 !important; } }

.fat {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 1; }

.fat-row {
  display: flex;
  flex-basis: 1%;
  flex-grow: 1;
  flex-shrink: 1; }

@media only screen and (max-width: 992px) {
  .no-mobile {
    display: none; } }

@media only screen and (min-width: 993px) {
  .no-desctop {
    display: none; } }

.pos-relative {
  position: relative; }

@media (min-width: 1600px) {
  .container {
    max-width: 1380px; } }

html {
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 62.5%; }

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  overflow-x: hidden; }

a,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
  cursor: pointer; }

ul {
  list-style-type: none; }

*,
*:before,
*:after {
  box-sizing: border-box; }

img {
  max-width: 100%; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  padding: 0;
  margin: 0; }

.container {
  max-width: 1450px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px; }

.container-small {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px; }

.container-grid {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: auto;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px; }

.header__nav.mobile-menu-visible {
  display: flex;
  transform: translateX(0); }

.header__nav.mobile-menu-visible .header__listItem.header__dropdownToggle .shevrone_icon {
  height: 24px;
  margin-left: 20px; }

#header.hide {
  transform: translateY(-100%); }

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
  min-height: 100px;
  height: auto;
  background-color: #F5F5F5;
  color: #000;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
  transition: transform .5s linear;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15); }
  @media only screen and (max-width: 920px) {
    .header {
      min-height: auto; } }
  .header__phones {
    display: flex;
    margin-left: 64px;
    align-items: center;
    justify-content: flex-end;
    min-height: 78px;
    margin-right: 0;
    padding-right: 15px; }
    @media only screen and (max-width: 1400px) {
      .header__phones {
        margin-left: 30px; } }
    @media only screen and (max-width: 1365px) {
      .header__phones {
        min-height: 50px;
        align-items: flex-end;
        padding-bottom: 5px; } }
    @media only screen and (max-width: 920px) {
      .header__phones {
        position: absolute;
        flex-direction: column;
        top: 57px;
        right: 63px;
        background-color: #fff;
        justify-content: center;
        margin-left: 0;
        min-height: auto;
        padding: 0;
        border-radius: 4px;
        box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.35);
        width: auto;
        z-index: 3; }
        .header__phones::after {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          background-color: #fff;
          position: absolute;
          transform: rotate(45deg);
          top: -2px;
          right: 20px; }
        .header__phones.closed {
          display: none; } }
    .header__phones-mobile {
      display: none;
      width: 73px;
      height: 73px;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 73px; }
      .header__phones-mobile-icon {
        fill: #8858A0; }
      .header__phones-mobile .shevrone_icon {
        width: 16px;
        height: 16px;
        transform: rotate(90deg);
        transition: all .3s ease-in-out; }
      .header__phones-mobile:hover {
        cursor: pointer; }
      .header__phones-mobile.opened .shevrone_icon {
        transform: rotate(-90deg); }
      @media only screen and (max-width: 920px) {
        .header__phones-mobile {
          display: flex; } }
  .header__nav {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    margin-left: 50px; }
    @media only screen and (max-width: 920px) {
      .header__nav {
        margin-left: 0; } }
  .header__phone-block {
    display: flex; }
    .header__phone-block:first-of-type {
      margin-right: 42px; }
      @media only screen and (max-width: 920px) {
        .header__phone-block:first-of-type {
          margin-right: 0; } }
    .header__phone-block svg {
      margin-right: 14px; }
    @media only screen and (max-width: 920px) {
      .header__phone-block {
        width: 100%;
        padding: 8px 20px; }
        .header__phone-block:not(:last-of-type) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.15); } }
  .header__right {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-end; }
    @media only screen and (max-width: 920px) {
      .header__right {
        width: auto; } }
  .header__phone {
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.8rem;
    margin-bottom: 5px;
    font-weight: bold;
    color: #000; }
  .header__address {
    font-size: 1.08rem;
    color: rgba(0, 0, 0, 0.6); }
    @media only screen and (max-width: 1200px) {
      .header__address {
        font-size: 1.26rem; } }
    @media only screen and (max-width: 992px) {
      .header__address {
        font-size: 1.44rem; } }
  .header__list {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    align-items: center; }
  .header__listItem {
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;
    border-top: 4px solid transparent;
    cursor: pointer;
    position: relative;
    transition: .15s;
    min-height: 65px; }
    .header__listItem .shevrone_icon {
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      height: 15px; }
    .header__listItem.menu_text_title {
      display: none;
      color: #8858a0;
      font-size: 22px;
      font-weight: bold;
      font-family: 'Lora', sans-serif;
      margin-bottom: 36px; }
      @media only screen and (max-width: 920px) {
        .header__listItem.menu_text_title {
          display: block; } }
    .header__listItem.active, .header__listItem:hover {
      border-top: 4px solid #8858A0;
      background-color: #e7e7e7; }
    @media only screen and (max-width: 1475px) {
      .header__listItem {
        padding-left: 10px;
        padding-right: 10px; } }
    @media only screen and (max-width: 920px) {
      .header__listItem {
        min-height: auto; } }
  .header__link {
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 18px;
    white-space: nowrap; }
    @media only screen and (max-width: 1612px) {
      .header__link {
        font-size: 16px; } }
  .header__logoWrapper {
    display: flex;
    align-items: center; }
  .header__logo {
    max-width: 999px;
    border-radius: 50%; }

.studioSubsection {
  margin-top: 100px; }
  .studioSubsection__header {
    position: relative; }
    .studioSubsection__header--content {
      grid-column: 2/span 6;
      padding-bottom: 11.1rem;
      color: #fff; }
  .studioSubsection__imageBg {
    position: absolute;
    height: 100%;
    width: 40%;
    right: 0;
    object-fit: cover; }
  .studioSubsection__title {
    font-family: "Lora", sans-serif;
    font-size: 5rem;
    font-weight: bold;
    margin-bottom: 3rem; }
  .studioSubsection__description {
    opacity: 0.8;
    font-size: 1.6rem;
    line-height: 2.8rem;
    margin-bottom: 4.5rem;
    max-width: 706px; }
  .studioSubsection__blockWrapper {
    display: flex;
    margin-bottom: 6.5rem; }
    @media only screen and (max-width: 400px) {
      .studioSubsection__blockWrapper {
        flex-wrap: wrap-reverse; } }
  .studioSubsection__age, .studioSubsection__price, .studioSubsection__office {
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    padding: 2.5rem;
    padding-right: 5rem;
    margin-right: 3rem; }
    .studioSubsection__age--title, .studioSubsection__price--title, .studioSubsection__office--title {
      font-weight: 500;
      font-size: 1.6rem;
      margin-bottom: 4.5rem; }
    .studioSubsection__age--text, .studioSubsection__age--currency, .studioSubsection__price--text, .studioSubsection__price--currency, .studioSubsection__office--text, .studioSubsection__office--currency {
      font-size: 1.8rem;
      margin-left: 0.5rem; }
    .studioSubsection__age--ageCount, .studioSubsection__age--priceCount, .studioSubsection__price--ageCount, .studioSubsection__price--priceCount, .studioSubsection__office--ageCount, .studioSubsection__office--priceCount {
      font-size: 4.2rem;
      font-weight: 300;
      display: flex;
      align-items: baseline; }
  .studioSubsection__office {
    margin-right: 0; }
    @media only screen and (max-width: 400px) {
      .studioSubsection__office--title {
        white-space: normal !important; } }
    .studioSubsection__office--content svg:not(:last-of-type) {
      margin-right: 10px; }
      @media only screen and (max-width: 920px) {
        .studioSubsection__office--content svg:not(:last-of-type) {
          margin-right: 5px; } }
    @media only screen and (max-width: 920px) {
      .studioSubsection__office--content svg {
        width: 20px; } }
    @media only screen and (max-width: 920px) {
      .studioSubsection__office {
        padding-right: 2.5rem; } }
    @media only screen and (max-width: 400px) {
      .studioSubsection__office {
        margin-bottom: 20px; } }
  @media only screen and (max-width: 400px) {
    .studioSubsection__price {
      margin-bottom: 20px; } }
  .studioSubsection__button {
    background-color: #FFC40C;
    color: #212121; }
  .studioSubsection__body {
    padding-top: 4.5rem;
    padding-bottom: 85px;
    -ms-grid-column-span: 7;
    -ms-grid-column: 2;
    grid-column: 2/span 7;
    color: #2C2C2C;
    font-size: 15px;
    line-height: 1.7; }
    .studioSubsection__body a {
      color: #8858A0; }
      .studioSubsection__body a:hover {
        text-decoration: underline; }

.studioSubsection__body ul,
.blogArticle__content ol {
  padding-left: 4rem; }

.studioSubsection__body li {
  position: relative;
  font-size: 15px; }

.studioSubsection__body li::before {
  content: "•";
  color: #8858a0;
  position: absolute;
  font-size: 3rem;
  left: -1.5rem;
  top: -5px; }

#trial_les_form_id .select2 {
  grid-row: 1;
  min-width: 365px;
  box-sizing: border-box; }
  @media only screen and (max-width: 1550px) {
    #trial_les_form_id .select2 {
      min-width: 258px; } }
  @media only screen and (max-width: 992px) {
    #trial_les_form_id .select2 {
      min-width: 180px; } }
  @media only screen and (max-width: 785px) {
    #trial_les_form_id .select2 {
      min-width: 277.72px;
      margin-left: 0; }
      #trial_les_form_id .select2 span {
        margin-right: 0;
        margin-left: 0; } }
  @media only screen and (max-width: 510px) {
    #trial_les_form_id .select2 {
      min-width: 100%; } }
  #trial_les_form_id .select2-container--default .select2-selection--single {
    background-color: transparent;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 27.5px;
    background-color: transparent;
    padding: 2rem 4.5rem;
    font-size: 1.6rem;
    box-sizing: border-box;
    -webkit-transition: all .25s;
    transition: all .25s;
    height: 60px; }
    @media only screen and (max-width: 1550px) {
      #trial_les_form_id .select2-container--default .select2-selection--single {
        height: 48px; } }
    @media only screen and (max-width: 1180px) {
      #trial_les_form_id .select2-container--default .select2-selection--single {
        height: 41.27px; } }
    @media only screen and (max-width: 992px) {
      #trial_les_form_id .select2-container--default .select2-selection--single {
        height: 36.47px; } }
    @media only screen and (max-width: 785px) {
      #trial_les_form_id .select2-container--default .select2-selection--single {
        height: 44px;
        padding: 2.7rem 3.7rem; } }
    @media only screen and (max-width: 510px) {
      #trial_les_form_id .select2-container--default .select2-selection--single {
        border-radius: 0px;
        padding: 2.4rem 2.6rem; } }
    #trial_les_form_id .select2-container--default .select2-selection--single .select2-selection__rendered {
      line-height: normal;
      color: #fff;
      font-size: 1.6rem;
      font-family: Roboto;
      font-weight: 200; }
      @media only screen and (max-width: 785px) {
        #trial_les_form_id .select2-container--default .select2-selection--single .select2-selection__rendered {
          margin-bottom: 5px; } }
      @media only screen and (max-width: 510px) {
        #trial_les_form_id .select2-container--default .select2-selection--single .select2-selection__rendered {
          height: auto;
          font-size: 14px; } }
    #trial_les_form_id .select2-container--default .select2-selection--single .select2-selection__placeholder {
      color: rgba(255, 255, 255, 0.5); }
    #trial_les_form_id .select2-container--default .select2-selection--single .select2-selection__arrow {
      top: 50%;
      transform: translateY(-55%) rotate(-45deg);
      right: 20px;
      transition: all .3s ease-in-out; }
      #trial_les_form_id .select2-container--default .select2-selection--single .select2-selection__arrow b {
        width: 8px;
        height: 8px;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        border-left: 1px solid rgba(255, 255, 255, 0.5); }
      @media only screen and (max-width: 1180px) {
        #trial_les_form_id .select2-container--default .select2-selection--single .select2-selection__arrow {
          ransform: translateY(-58%) rotate(-45deg); } }
      @media only screen and (max-width: 785px) {
        #trial_les_form_id .select2-container--default .select2-selection--single .select2-selection__arrow {
          top: 38%; } }
      @media only screen and (max-width: 510px) {
        #trial_les_form_id .select2-container--default .select2-selection--single .select2-selection__arrow {
          top: 44%; } }
    #trial_les_form_id .select2-container--default .select2-selection--single:focus, #trial_les_form_id .select2-container--default .select2-selection--single:active {
      outline: 0px; }
  #trial_les_form_id .select2-container--default .select2-results > .select2-results__options {
    background-color: #fff; }
    #trial_les_form_id .select2-container--default .select2-results > .select2-results__options .select2-results__option {
      color: black;
      font-size: 1.8rem;
      line-height: 2.52rem;
      padding-left: 10px; }
      #trial_les_form_id .select2-container--default .select2-results > .select2-results__options .select2-results__option:hover {
        background-color: #ffc40c; }
      #trial_les_form_id .select2-container--default .select2-results > .select2-results__options .select2-results__option--highlighted {
        background-color: #f5f5f5; }
  #trial_les_form_id .select2-container--open .select2-selection--single {
    border-color: #fff; }
    #trial_les_form_id .select2-container--open .select2-selection--single .select2-selection__arrow {
      transform: translateY(-46%) rotate(-225deg); }

@media only screen and (max-width: 992px) {
  #trial_les_form_id form p input[type=submit] {
    grid-row: 2; } }

#contacts .form-row, .studioSubsection__footer--order .form-row {
  display: flex;
  align-items: center;
  position: relative; }
  #contacts .form-row .form-square-select span[role="alert"], .studioSubsection__footer--order .form-row .form-square-select span[role="alert"] {
    top: -46px; }
  @media only screen and (max-width: 576px) {
    #contacts .form-row, .studioSubsection__footer--order .form-row {
      flex-direction: column;
      align-items: flex-start; }
      #contacts .form-row > span, .studioSubsection__footer--order .form-row > span {
        width: 100%; }
      #contacts .form-row input, .studioSubsection__footer--order .form-row input {
        width: 100%;
        max-width: 100% !important; }
      #contacts .form-row .form-square-select, .studioSubsection__footer--order .form-row .form-square-select {
        margin-top: 20px;
        margin-left: 0;
        width: 100%; }
        #contacts .form-row .form-square-select .select2, .studioSubsection__footer--order .form-row .form-square-select .select2 {
          min-width: 100%; } }

#contacts .select2, .studioSubsection__footer--order .select2 {
  grid-row: 1;
  min-width: 365px;
  box-sizing: border-box; }
  @media only screen and (max-width: 992px) {
    #contacts .select2, .studioSubsection__footer--order .select2 {
      min-width: 290px; } }
  @media only screen and (max-width: 785px) {
    #contacts .select2, .studioSubsection__footer--order .select2 {
      min-width: 100%; } }
  @media only screen and (max-width: 650px) {
    #contacts .select2, .studioSubsection__footer--order .select2 {
      min-width: 100%; } }
  #contacts .select2-container--default .select2-selection--single, .studioSubsection__footer--order .select2-container--default .select2-selection--single {
    background-color: transparent;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(33, 33, 33, 0.5);
    background-color: transparent;
    padding: 18px 30px;
    font-size: 1.6rem;
    box-sizing: border-box;
    -webkit-transition: all .25s;
    transition: all .25s;
    height: 55px; }
    #contacts .select2-container--default .select2-selection--single .select2-selection__rendered, .studioSubsection__footer--order .select2-container--default .select2-selection--single .select2-selection__rendered {
      line-height: normal;
      color: #2c2c2c;
      font-size: 16px;
      font-family: Roboto;
      font-weight: 400; }
    #contacts .select2-container--default .select2-selection--single .select2-selection__placeholder, .studioSubsection__footer--order .select2-container--default .select2-selection--single .select2-selection__placeholder {
      color: rgba(44, 44, 44, 0.5); }
    #contacts .select2-container--default .select2-selection--single .select2-selection__arrow, .studioSubsection__footer--order .select2-container--default .select2-selection--single .select2-selection__arrow {
      top: 50%;
      transform: translateY(-60%) rotate(-45deg);
      right: 20px;
      transition: all .3s ease-in-out; }
      #contacts .select2-container--default .select2-selection--single .select2-selection__arrow b, .studioSubsection__footer--order .select2-container--default .select2-selection--single .select2-selection__arrow b {
        width: 8px;
        height: 8px;
        border: none;
        border-bottom: 1px solid rgba(44, 44, 44, 0.5);
        border-left: 1px solid rgba(44, 44, 44, 0.5); }
    #contacts .select2-container--default .select2-selection--single:focus, #contacts .select2-container--default .select2-selection--single:active, .studioSubsection__footer--order .select2-container--default .select2-selection--single:focus, .studioSubsection__footer--order .select2-container--default .select2-selection--single:active {
      outline: 0px; }
  #contacts .select2-container--default .select2-results > .select2-results__options, .studioSubsection__footer--order .select2-container--default .select2-results > .select2-results__options {
    background-color: #fff; }
    #contacts .select2-container--default .select2-results > .select2-results__options .select2-results__option, .studioSubsection__footer--order .select2-container--default .select2-results > .select2-results__options .select2-results__option {
      color: black;
      font-size: 1.8rem;
      line-height: 2.52rem;
      padding-left: 10px; }
      #contacts .select2-container--default .select2-results > .select2-results__options .select2-results__option:hover, .studioSubsection__footer--order .select2-container--default .select2-results > .select2-results__options .select2-results__option:hover {
        background-color: #8858a0; }
      #contacts .select2-container--default .select2-results > .select2-results__options .select2-results__option--highlighted, .studioSubsection__footer--order .select2-container--default .select2-results > .select2-results__options .select2-results__option--highlighted {
        background-color: #f5f5f5; }
  #contacts .select2-container--open .select2-selection--single, .studioSubsection__footer--order .select2-container--open .select2-selection--single {
    border: 1px solid #2c2c2c; }
    #contacts .select2-container--open .select2-selection--single .select2-selection__arrow, .studioSubsection__footer--order .select2-container--open .select2-selection--single .select2-selection__arrow {
      transform: translateY(-46%) rotate(-225deg); }

#contacts .form-square-select {
  padding-top: 8px; }

.studioSubsection__footer--order .form-square-select {
  margin-left: 25px; }

.contacts__itemTitle {
  font-family: "Lora", sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 35px;
  margin-top: 50px; }

.contacts__inform {
  grid-column: 1/span 6; }

.contacts__subscribe {
  grid-column: 7/span 4; }

.contacts__social {
  grid-column: 11/span 1;
  justify-self: end; }

.contacts__items {
  display: flex; }
  @media only screen and (max-width: 992px) {
    .contacts__items {
      flex-direction: column; } }

.contacts__contactsItemWrapper {
  display: flex;
  color: rgba(255, 255, 255, 0.8);
  line-height: 28px; }
  .contacts__contactsItemWrapper:not(:last-of-type) {
    margin-right: 40px; }
    @media only screen and (max-width: 992px) {
      .contacts__contactsItemWrapper:not(:last-of-type) {
        margin-right: 0;
        margin-bottom: 20px; } }

.contacts__inform .contacts__contactsItemWrapper {
  font-size: 1.6rem; }

.contacts__icon {
  margin-right: 18px; }
  .contacts__icon svg {
    width: 28px; }

.contacts__address {
  position: relative;
  opacity: 0.6;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 1.26rem;
  line-height: 1.26rem;
  margin-top: 5px; }
  .contacts__address_small {
    font-size: 0.9rem; }
    @media only screen and (max-width: 992px) {
      .contacts__address_small {
        font-size: 8px; } }
  @media only screen and (max-width: 992px) {
    .contacts__address {
      font-size: 12px;
      line-height: 11px; } }

.contacts__phones {
  display: flex;
  flex-direction: column;
  position: relative;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 1.98rem;
  font-weight: 500;
  line-height: 2.16rem;
  white-space: nowrap; }
  @media only screen and (max-width: 992px) {
    .contacts__phones {
      font-size: 18px;
      line-height: 22px; } }

.contacts__iconWrapper {
  height: 55px;
  width: 55px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  margin-right: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.contacts__iconWrapper img {
  height: 100%;
  width: 100%; }

.educationBG {
  background: -webkit-linear-gradient(left, #5ACA14 0%, #4CB00C 100%);
  background: linear-gradient(90deg, #5ACA14 0%, #4CB00C 100%); }

.artBG {
  background: -webkit-linear-gradient(left, #00B0FF 0%, #0199DD 100%);
  background: linear-gradient(90deg, #00B0FF 0%, #0199DD 100%); }

.preschoolBG {
  background: -webkit-linear-gradient(left, #FA5129 0%, #E04722 100%);
  background: linear-gradient(90deg, #FA5129 0%, #E04722 100%); }

.individualBG {
  background: -webkit-linear-gradient(left, #F18C0C 0%, #DA800C 100%);
  background: linear-gradient(90deg, #F18C0C 0%, #DA800C 100%); }

.coursesBG {
  background: -webkit-linear-gradient(left, #EB3F7A 0%, #D32F67 100%);
  background: linear-gradient(90deg, #EB3F7A 0%, #D32F67 100%); }

.costumesBG {
  background: -webkit-linear-gradient(left, #07C394 0%, #06B086 100%);
  background: linear-gradient(90deg, #07C394 0%, #06B086 100%); }

.defaultBG {
  background: #8858A0 !important; }

.sectionTitle {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  color: #212121;
  margin-bottom: 60px;
  padding-top: 100px; }

.btn {
  background: #8858A0;
  border-radius: 3rem;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
  padding: 2rem 5rem;
  color: #fff;
  font-size: 1.8rem;
  outline: none;
  border: none;
  display: inline-block;
  cursor: pointer; }

.btn-press {
  transition: all .3s; }

.btn-press:hover {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  transform: translateY(2px); }

.slick-prev,
.slick-next {
  height: 44px;
  width: 44px;
  border-radius: 50%; }

.slick-prev:hover,
.slick-next:hover {
  background-color: rgba(0, 0, 0, 0.1);
  height: 44px;
  width: 44px;
  border-radius: 50%;
  transition: background-color .25s; }

.slick-initialized {
  visibility: visible !important; }

.display-beyond {
  position: absolute;
  left: -200%; }

.mfp-container.mfp-s-ready.mfp-image-holder:hover {
  cursor: default; }

.mfp-close {
  cursor: pointer !important; }

.mfp-content {
  width: 70vw;
  height: 90vh; }

.mfp-figure,
.mfp-figure figure {
  height: 100%;
  width: 100%; }

.mfp-figure:after {
  background: transparent;
  box-shadow: none; }

.mfp-figure figure {
  height: calc(100% - 88px);
  margin-top: 44px;
  display: flex;
  align-items: center; }

img.mfp-img {
  padding: 0;
  box-shadow: 0px 35px 90px rgba(0, 0, 0, 0.5); }

.mfp-bottom-bar {
  width: auto;
  left: 50%;
  margin-top: -15px; }

.mfp-figure figure img {
  max-height: 100% !important; }

.mfp-arrow-right {
  right: 3vw; }

.mfp-arrow-left {
  left: 3vw; }

.eventsReportMediaItemWrapper iframe {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 999; }

input,
textarea {
  outline: none;
  transition: all .25s; }

.defaultBG {
  background: #8858A0 !important; }

.overflow-hidden {
  overflow: hidden; }

.hideInput {
  visibility: hidden; }

.btn,
.studioSubsection input[type='submit'],
#contacts .prefooter-contact-form input[type=submit],
.studioSubsection input[type='submit'],
#trial_les_form_id form p input[type='submit'],
.text-wrapper .button {
  font-size: 16px !important;
  border-radius: 10rem;
  font-weight: 500;
  font-family: "Roboto", sans-serif; }

#trial_les_form_id form p input {
  font-size: 16px; }
  #trial_les_form_id form p input:focus, #trial_les_form_id form p input:active {
    border-color: #fff; }

.mainSlider {
  margin-top: 50px;
  margin-bottom: 50px; }

.mainSlider .item {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  font-size: 50px;
  color: #F5F5F5;
  padding: 0 0 0 100px;
  height: 700px;
  border-radius: 4px; }

.mainSlider .item img {
  margin-left: auto;
  align-self: end;
  height: 100%;
  object-fit: contain;
  object-position: bottom; }

.mainSlider .tag {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 45px;
  display: inline-block;
  padding-bottom: 10px;
  border-bottom: 3px solid #fff;
  margin-bottom: 30px;
  position: absolute;
  top: 65px; }

.mainSlider .title {
  font-family: 'Lora', sans-serif;
  line-height: 60px;
  font-size: 50px;
  color: white;
  font-weight: bold;
  margin-bottom: 40px;
  margin-top: 120px; }

.mainSlider .description {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  line-height: 28px;
  margin-bottom: 40px; }

.mainSlider .text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative; }

.text-wrapper .button {
  border-radius: 30px;
  background-color: white;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
  font-size: 18px;
  font-weight: 500;
  color: #212121;
  padding: 20px 60px;
  transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
  position: absolute;
  bottom: 65px; }

.slick-dots {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 60px;
  bottom: auto; }

.slick-dots li.slick-active button:before {
  color: #8858a0; }

.slick-dots li button:before {
  font-size: 12px;
  color: rgba(66, 66, 66, 0.5); }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  color: #8858a0; }

.slick-prev {
  transform: rotateY(180deg);
  left: -60px; }

.slick-next {
  right: -60px;
  margin-top: 20px; }

.slick-prev:before,
.slick-next:before {
  content: url("../knowledge/img/icons/arrow_shevrone_icon.svg"); }

.mfp-arrow:before,
.mfp-arrow:after {
  content: none; }

.mfp-arrow-left {
  background: url("../knowledge/img/icons/arrow_shevrone_icon_white_left.svg") no-Repeat top left !important;
  width: 40px;
  height: 40px; }

.mfp-arrow-right {
  background: url("../knowledge/img/icons/arrow_shevrone_icon_white.svg") no-Repeat top left !important;
  width: 40px;
  height: 40px; }

.mfp-arrow-left::before,
.mfp-arrow-right::before {
  display: none; }

.col2 {
  -ms-grid-column: span 2;
  grid-column: span 2; }

.col3 {
  -ms-grid-column: span 3;
  grid-column: span 3; }

.row2 {
  -ms-grid-row: span 2;
  grid-row: span 2; }

.row3 {
  -ms-grid-row: span 2;
  grid-row: span 2; }

.headerSlider {
  margin-top: 150px; }

.headerSlider > .container {
  visibility: hidden; }

.hamburger-icon {
  display: none;
  min-width: 73px;
  height: 73px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #8858A0;
  transform: translateX(30px);
  transition: all .3s ease-in-out;
  cursor: pointer;
  z-index: 3; }

.arrowBack {
  display: none;
  position: fixed;
  z-index: 3;
  transform: rotateY(180deg);
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  left: -10px;
  cursor: pointer; }

.arrowBack.visible {
  display: flex; }

.hamburger-icon span {
  width: 20px;
  height: 3px;
  background-color: #fff;
  transition: all .3s ease-in-out; }

.hamburger-icon span:not(:last-child) {
  margin-bottom: 5px; }

.hamburger-icon.active {
  background-color: #8858A0; }

.hamburger-icon.active span {
  background-color: #fff; }

.hamburger-icon.active span:first-child {
  transform: rotate(45deg) translate(6px);
  margin-left: -6px; }

.hamburger-icon.active span:last-child {
  transform: rotate(-45deg) translate(5px);
  margin-left: -6px; }

.hamburger-icon.active span:nth-child(2) {
  display: none; }

.header__dropdownItem {
  position: relative;
  background: #fff;
  color: #000; }

.header__dropdownItem .shevrone_icon {
  margin-left: auto;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  height: 15px;
  width: 15px; }

.header__dropdown {
  position: absolute;
  left: 0;
  display: none;
  opacity: 0;
  -webkit-transition: all .3s ease-in-out;
  transition: transform .3s ease-in-out;
  z-index: 1;
  min-width: 270px;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.35); }

.header__dropdownItem:hover {
  color: #fff; }

.header__dropdownItem:hover .header__link svg {
  fill: #fff; }

.header__dropdown .education:hover {
  background: -webkit-linear-gradient(left, #5ACA14 0%, #4CB00C 99.48%);
  background: linear-gradient(90deg, #5ACA14 0%, #4CB00C 99.48%); }

.header__dropdown .art:hover {
  background: -webkit-linear-gradient(left, #00B0FF 0%, #0199DD 100%);
  background: linear-gradient(90deg, #00B0FF 0%, #0199DD 100%); }

.header__dropdown .art:hover .header__dropdown2 {
  opacity: 1; }

.header__dropdown .preschoolBG:hover {
  background: -webkit-linear-gradient(left, #FA5129 0%, #E04722 100%);
  background: linear-gradient(90deg, #FA5129 0%, #E04722 100%); }

.header__dropdown .coursesBG:hover {
  background: -webkit-linear-gradient(left, #EB3F7A 0%, #D32F67 100%);
  background: linear-gradient(90deg, #EB3F7A 0%, #D32F67 100%); }

.header__dropdown .costumesBG:hover {
  background: -webkit-linear-gradient(left, #07C394 0%, #06B086 100%);
  background: linear-gradient(90deg, #07C394 0%, #06B086 100%); }

.header__dropdown .individual:hover,
.header__dropdownItem.individual:hover {
  background: -webkit-linear-gradient(left, #F18C0C 0%, #DA800C 100%);
  background: linear-gradient(90deg, #F18C0C 0%, #DA800C 100%); }

.header__dropdown2 {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  transform: translateX(100%);
  background-color: #fff;
  opacity: 0;
  transition: all .3s linear;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12); }

.header__dropdown2 .header__link {
  color: #000; }

.header__dropdown .header__link {
  padding: 10px 25px; }

.header__dropdown2.art .header__dropdownItem a:hover {
  color: #fff;
  background: linear-gradient(90deg, #42A5F5 0%, #3783C1 100%); }

.header__dropdown2.art:hover {
  background: #fff; }

.header__dropdownItem.art:hover .header__dropdown2.art {
  display: block;
  opacity: 1; }

.header__dropdownItem.education:hover .header__dropdown2.education {
  display: block;
  opacity: 1;
  z-index: 2;
  background: #fff; }

.header__dropdown2.education .header__dropdownItem a:hover {
  color: #fff;
  background: -webkit-linear-gradient(left, #5ACA14 0%, #4CB00C 99.48%);
  background: linear-gradient(90deg, #5ACA14 0%, #4CB00C 99.48%); }

.header__dropdownItem.individual .shevrone_icon {
  margin-left: 20px; }

.header__dropdownItem.individual:hover .header__dropdown2.individual {
  display: block;
  opacity: 1; }

.header__dropdown2.individual:hover {
  background: #fff; }

.header__dropdown2.individual .header__dropdownItem a:hover {
  color: #fff;
  background: -webkit-linear-gradient(left, #F18C0C 0%, #DA800C 100%);
  background: linear-gradient(90deg, #F18C0C 0%, #DA800C 100%); }

.art .shevrone_icon {
  margin-left: auto; }

.header__dropdownToggle {
  padding-right: 0; }

.header__dropdownToggle:hover .header__dropdown {
  display: block;
  opacity: 1;
  background-color: #fff; }

.header__dropdownItem.art:hover .shevrone_icon {
  fill: #fff; }

.blogArticle__content ol {
  counter-reset: ol-counter;
  list-style: none; }

.blogArticle__content ol li {
  counter-increment: ol-counter;
  position: relative;
  padding-left: 16px; }

.blogArticle__content ol li::before {
  content: counter(ol-counter) ".";
  position: absolute;
  top: 0;
  left: 0;
  color: #8858a0; }

.blogArticle__content ul li {
  position: relative;
  padding-left: 4rem; }

.blogArticle__content ul li:before {
  content: '\2022';
  position: absolute;
  font-size: 3rem;
  top: 0;
  left: 1.5rem;
  color: #8858a0; }

.studioSubsection__bodyHeading {
  margin-bottom: 30px;
  margin-top: 40px;
  color: #212121;
  font-size: 26px;
  font-weight: 500; }

.studioSubsection__footer {
  background: linear-gradient(90deg, #FFC40C 55%, #8858A0 55%); }
  @media only screen and (max-width: 1366px) {
    .studioSubsection__footer {
      background: linear-gradient(90deg, #FFC40C 60%, #8858A0 60%); } }

.studioSubsection__footer form {
  position: relative; }

.studioSubsection__footer--prices,
.studioSubsection__footer--order {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 4.5rem 2rem 3.5rem 0; }

.studioSubsection__footer--order {
  grid-column: 1/span 7; }

.studioSubsection__footer--prices {
  grid-column: 9/-1; }

.studioSubsection__footer--orderTitle,
.studioSubsection__footer--pricesTitle {
  font-family: "Lora", sans-serif;
  color: #212121;
  font-size: 3.4rem;
  font-weight: bold;
  margin-bottom: 1.5rem; }

.studioSubsection__footer--pricesTitle {
  color: #fff; }

.studioSubsection__footer--orderDescription,
.studioSubsection__footer--pricesDescription {
  opacity: .8;
  font-size: 1.6rem;
  line-height: 2.8rem;
  margin-bottom: 3rem;
  max-width: 520px; }

.studioSubsection__footer--pricesDescription {
  color: #fff; }

.studioSubsection__footer--orderButton,
.studioSubsection__footer--pricesLink {
  color: #212121;
  background-color: #fff;
  font-weight: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: auto;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25); }

.studioSubsection__footer--pricesLink {
  padding: 1.15rem 5rem; }

.studioSubsection__footer--pricesLink img {
  margin-right: 5px; }

.studioSubsection__footer--orderInput {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 20px 30px;
  background-color: transparent;
  margin-bottom: 2rem;
  max-width: 340px;
  width: 100%; }

.studioSubsection .wpcf7-form-control-wrap input {
  height: 5.5rem;
  background-color: transparent;
  resize: none;
  border: 1px solid rgba(33, 33, 33, 0.5);
  border-radius: 4px;
  padding: 15px 30px;
  color: #2c2c2c;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  max-width: 342px;
  height: 55px; }

.studioSubsection .wpcf7-form-control-wrap input:focus {
  border: 1px solid #212121; }

.studioSubsection input[type='submit'] {
  color: #212121;
  background-color: #fff;
  font-weight: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: auto;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding: 2rem 5rem;
  font-size: 1.8rem;
  outline: none;
  border: none;
  font-weight: 500;
  margin-top: 2rem;
  transition: transform .3s;
  cursor: pointer; }

.studioSubsection input[type='submit']:hover {
  transform: translateY(2px); }

.studioSubsection div.wpcf7-response-output {
  color: #8858A0;
  border: none;
  position: relative;
  margin: 0;
  font-size: 1.4rem; }

.studioSubsection span.wpcf7-not-valid-tip::after {
  content: '';
  display: block;
  height: 7px;
  width: 7px;
  background-color: #fff;
  transform: rotate(45deg);
  position: absolute;
  bottom: -3px;
  left: 18px; }

.studioSubsection span.wpcf7-not-valid-tip {
  color: #8858A0;
  position: absolute;
  width: auto;
  height: auto;
  top: -27px;
  left: 2.7rem;
  background: #fff;
  padding: 2px 10px;
  border-radius: 50px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  font-size: 10px; }

.ajax-loader.is-active {
  display: none !important; }

#ourServises {
  padding-bottom: 150px; }

.ourServises__separate {
  margin-top: 100px; }

.ourServises__separate .ourServises__title {
  font-size: 5rem; }

#ourServises .education {
  background: -webkit-linear-gradient(left, #5ACA14 0%, #4CB00C 99.48%);
  background: linear-gradient(90deg, #5ACA14 0%, #4CB00C 99.48%); }

#ourServises .art {
  background: -webkit-linear-gradient(left, #00B0FF 0%, #0199DD 100%);
  background: linear-gradient(90deg, #00B0FF 0%, #0199DD 100%); }

#ourServises .preschool {
  background: -webkit-linear-gradient(left, #FA5129 0%, #E04722 100%);
  background: linear-gradient(90deg, #FA5129 0%, #E04722 100%); }

#ourServises .individual {
  background: -webkit-linear-gradient(left, #F18C0C 0%, #DA800C 100%);
  background: linear-gradient(90deg, #F18C0C 0%, #DA800C 100%); }

#ourServises .courses {
  background: -webkit-linear-gradient(left, #EB3F7A 0%, #D32F67 100%);
  background: linear-gradient(90deg, #EB3F7A 0%, #D32F67 100%); }

#ourServises .costumes {
  background: -webkit-linear-gradient(left, #07C394 0%, #06B086 100%);
  background: linear-gradient(90deg, #07C394 0%, #06B086 100%); }

.ourServises__title.sectionTitle {
  padding-top: 9rem; }

.ourServises__grid {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-auto-flow: dense;
  grid-auto-rows: minmax(min-content, 450px);
  grid-gap: 35px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: "Lora", sans-serif; }

.ourServises__item {
  padding: 50px;
  border-radius: 4px;
  font-size: 34px;
  font-weight: bold;
  color: #fff;
  display: block;
  height: 100%;
  position: relative;
  -webkit-transition: all .3s linear;
  transition: all .3s linear; }

.ourServises__item:hover {
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.35); }

.ourServises__itemTitle {
  font-family: "Lora", sans-serif;
  font-size: 34px;
  font-weight: bold;
  position: relative;
  z-index: 1; }

.ourServises__item:hover .ourServises__itemTitle svg {
  opacity: 1;
  -webkit-transform: translate(50px);
  -ms-transform: translate(50px);
  transform: translate(50px); }

.ourServises__itemTitle svg {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 40px;
  fill: #fff;
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
  -webkit-transition: opacity .4s linear, -webkit-transform .4s linear;
  transition: opacity .4s linear, transform .4s linear, -webkit-transform .4s linear;
  height: 50px;
  width: 50px; }

.ourServises__description {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  opacity: 0.8;
  font-weight: 100; }

.ourServises__itemImg {
  position: absolute;
  bottom: 0;
  right: 0; }

.ourServises__art {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 85%; }

.ourServises__courses {
  bottom: -10px;
  right: -30px;
  max-width: 85%; }

.trial-wrapper {
  background: #8858A0; }

#trial {
  background: #8858A0;
  color: #fff;
  padding-top: 6.5rem;
  padding-bottom: 6.5rem; }

#trial form,
#trial_les_form_id form {
  position: relative; }

#trial .container {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px; }

.trial__content {
  grid-column: 1/-1; }

.trial__title {
  font-family: "Lora", sans-serif;
  font-size: 3.6rem;
  font-weight: bold;
  margin-bottom: 2rem; }

.trial__description {
  max-width: 530px;
  margin-bottom: 4rem;
  font-size: 1.6rem; }

#ourWorkshop {
  color: #212121; }

#ourWorkshop .wrapper {
  background-color: #ECEFF1;
  position: relative; }

.ourWorkshop__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #ECEFF1;
  height: 725px !important; }

.ourWorkshop__leftBox {
  padding: 100px 0 100px 20px;
  max-width: 575px;
  justify-self: end;
  margin-right: 130px;
  position: relative; }

.ourWorkshop__rightBox-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4px; }

.ourWorkshop__itemTitle {
  font-family: "Lora", sans-serif;
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 60px; }

.ourWorkshop__itemDescription {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 70px;
  max-width: 575px; }

.ourWorkshop__button-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 120px; }

.ourWorkshop__detail-link:hover {
  color: #fff; }

.video-link {
  display: inline-flex;
  align-items: center;
  height: 62px;
  color: #8858A0;
  background-color: transparent;
  border: none;
  font-weight: 500;
  box-shadow: none; }

.video-link:hover {
  color: #8858A0; }

#ourWorkshop .slick-initialized .slick-slide {
  display: grid;
  grid-template-columns: 1fr 1fr; }

#ourWorkshop .slick-dots {
  bottom: 50px;
  justify-content: flex-start;
  max-width: 555px;
  right: calc(50% + 130px); }

#ourWorkshop .slick-dotted.slick-slider {
  margin-bottom: 0; }

#contacts {
  background: #8858A0;
  padding-bottom: 70px;
  color: #fff;
  padding-top: 280px;
  position: relative; }

#contact-page-body #contacts {
  padding-top: 0; }

#contacts .container {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px; }

#contacts .prefooter-contact-form {
  position: absolute;
  left: 50%;
  top: -50%;
  transform: translateX(-50%);
  max-width: 940px;
  width: 100%;
  background-color: #FFC40C;
  border-radius: 4px;
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.25);
  padding-top: 50px;
  padding-bottom: 80px;
  padding: 50px 110px 80px 110px;
  z-index: 1; }

#contacts .prefooter-contact-form.contact-page-form {
  top: -850px;
  width: 90vw; }

#contacts .prefooter-contact-form input,
#contacts .prefooter-contact-form textarea {
  background-color: transparent;
  resize: none;
  border: 1px solid rgba(33, 33, 33, 0.5);
  border-radius: 4px;
  padding: 15px 30px;
  color: #2c2c2c;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  width: 100%; }

#contacts .prefooter-contact-form input {
  height: 55px;
  margin-bottom: 12px; }

#contacts .prefooter-contact-form input[type=submit] {
  position: absolute;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5rem;
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
  padding: 2rem 5rem;
  color: #212121;
  font-size: 1.8rem;
  font-weight: 500;
  outline: none;
  border: none;
  transition: all .25s;
  cursor: pointer;
  width: auto; }

#contacts .prefooter-contact-form input[type=submit]:hover {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  transform: translateY(2px) translateX(-50%); }

#contacts .prefooter-contact-form textarea {
  height: 190px;
  transition: all .25s; }

#contacts .prefooter-contact-form textarea:focus {
  border: 2px solid #000; }

#contacts #easy-newsletter-form input {
  background-color: transparent;
  color: white;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  padding: 18px 30px;
  transition: all .25s; }

#contacts #easy-newsletter-form input:focus {
  border: 2px solid #fff;
  border-right: 0; }

#contacts .prefooter-contact-form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
  font-size: 16px; }

#contacts .prefooter-contact-form input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.3);
  font-size: 16px; }

#contacts .prefooter-contact-form input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
  font-size: 16px; }

#contacts .wpcf7-form-control-wrap input:focus {
  border: 1px solid #212121; }

#contacts .wpcf7-form-control-wrap input::-webkit-input-placeholder {
  color: rgba(44, 44, 44, 0.5);
  font-size: 16px; }

#contacts .wpcf7-form-control-wrap input::-webkit-input-placeholder {
  color: rgba(44, 44, 44, 0.5);
  font-size: 16px; }

.prefooter-contact-form__input-wrapper {
  display: flex;
  justify-content: center; }

.prefooter-contact-form__input-wrapper > div {
  flex-grow: 1; }

.prefooter-contact-form__input-wrapper span[role='alert'] {
  color: #8858A0;
  position: absolute;
  width: 205px;
  top: -35px;
  left: 11px;
  background: #fff;
  padding: 2px 10px;
  border-radius: 50px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  font-size: 10px; }

.prefooter-contact-form__input-wrapper span[role='alert']::after {
  content: '';
  display: block;
  height: 7px;
  width: 7px;
  background-color: #fff;
  transform: rotate(45deg);
  position: absolute;
  bottom: -3px;
  left: 18px; }

.prefooter-contact-form__input-wrapper .your-comment span[role='alert'] {
  top: -193px;
  left: 11px; }

.prefooter-contact-form div[role='alert'] {
  font-size: 11px;
  position: absolute;
  border: none;
  color: #fff;
  bottom: 70px; }

.prefooter-contact-form__input-wrapper-left {
  margin-right: 20px; }

.prefooter-contact-form__title {
  text-align: center;
  padding-bottom: 3.5rem;
  color: #2C2C2C;
  font-family: "Lora", sans-serif;
  font-size: 2.8rem;
  font-weight: bold; }

#easy-newsletter-form {
  display: flex;
  height: 55px; }

#easy-newsletter-form .error.wpens-error {
  color: #8858A0;
  position: absolute;
  width: auto;
  height: auto;
  top: -1rem;
  left: 2.7rem;
  background: #fff;
  padding: 2px 10px;
  border-radius: 50px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  font-size: 10px; }

#easy-newsletter-form .error.wpens-error::after {
  content: '';
  display: block;
  height: 7px;
  width: 7px;
  background-color: #fff;
  transform: rotate(45deg);
  position: absolute;
  bottom: -3px;
  left: 18px; }

#easy-newsletter-form input,
#easy-newsletter-form button {
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.3); }

#easy-newsletter-form input {
  border-top-left-radius: 27.5px;
  border-bottom-left-radius: 27.5px; }

#easy-newsletter-form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5); }

#easy-newsletter-form input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5); }

#easy-newsletter-form input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5); }

#easy-newsletter-form button {
  width: 55px; }

#easy-newsletter-form > div {
  height: 100%;
  position: relative; }

#easy-newsletter-submit {
  background: #FFC40C url("./img/icons/send_icon.svg") center center no-repeat;
  border-top-right-radius: 27.5px;
  border-bottom-right-radius: 27.5px; }

#news {
  padding-bottom: 80px; }

#news .container {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px; }

.news__leftBox {
  -ms-grid-column-span: 9;
  -ms-grid-column: 1;
  grid-column: 1/span 9; }

.news__leftBoxContent {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;
  grid-auto-rows: minmax(min-content, 450px);
  grid-gap: 25px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff; }

.news__rightBox {
  -ms-grid-column-span: 4;
  -ms-grid-column: 10;
  grid-column: 10/span 4;
  padding-left: 15px; }

.news__rightBox iframe {
  margin-bottom: 25px;
  border: 1px solid rgba(0, 0, 0, 0.3) !important; }

.news__leftTitle,
.news__rightTitle {
  color: #212121;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 40px; }

.news__post {
  padding: 35px;
  transition: all .2s ease-in;
  cursor: pointer;
  position: relative; }

.news__post::after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 1%, rgba(0, 0, 0, 0.45) 30%, rgba(0, 0, 0, 0) 100%);
  transition: background .2s ease-in; }

.news__post:hover::after {
  background: rgba(255, 196, 12, 0.92); }

.news__post > * {
  position: relative;
  z-index: 1; }

.news__postDate {
  font-size: 1.4rem; }

.news__imgBG:hover {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%); }

.news__post:hover .news__postTag::after {
  background: #2c2c2c; }

.news__post:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.35);
  color: #2c2c2c; }

.news__postTag {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 35px; }

.news__postTag::after {
  content: '';
  display: block;
  position: absolute;
  height: 3px;
  width: 50px;
  background: #fff;
  bottom: -10px;
  transition: background .2s ease-in; }

.news__post:nth-child(3) {
  -ms-grid-column: span 2;
  grid-column: span 2; }

.news__postTitle {
  margin-bottom: 35px;
  font-family: "Lora", sans-serif;
  font-size: 28px;
  font-weight: bold;
  max-width: 646px; }

.news__imgBG {
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  object-fit: cover; }

.news__moreNews {
  margin-top: 65px; }

.news__moreNews:hover {
  color: #fff; }

footer {
  background-color: #5E3D6F;
  color: #fff;
  font-size: 16px; }

footer .container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 65px; }

.footer__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 610px) {
    .footer__wrapper {
      flex-direction: column-reverse; } }

#menu-footer-menu {
  display: flex; }

#menu-footer-menu li {
  margin-left: 45px; }

.footer__vacancy {
  margin-right: 50px; }

.footer__copy {
  opacity: .7; }

#aboutUs {
  color: #fff;
  background: -webkit-linear-gradient(left, #8858A0 66.66%, #FFC40C 33.33%);
  background: linear-gradient(90deg, #8858A0 66.66%, #FFC40C 33.33%);
  overflow: hidden;
  margin-top: 100px; }

#aboutUs .container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px; }

.aboutUs__content {
  grid-column: 2/span 5;
  grid-row: 1; }

.aboutUs__imgWrapper {
  grid-column: 8/-1;
  grid-row: 1;
  align-self: end;
  margin-bottom: -5px;
  margin-right: -8rem; }

.aboutUs__subtitle {
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  padding-top: 22rem; }

.aboutUs__title {
  font-family: "Lora", sans-serif;
  font-size: 5.5rem;
  font-weight: bold;
  color: #FFC40C;
  margin-bottom: 4.4rem;
  margin-left: -30px; }

.aboutUs__description {
  font-size: 1.6rem;
  padding-bottom: 25.5rem;
  line-height: 2.8rem; }

.aboutUsMedia {
  padding-top: 6.5rem; }

.aboutUsMedia .container {
  visibility: hidden; }

.aboutUsMedia_content_wrapper {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.aboutUsMedia .slick-initialized .slick-slide {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 9rem;
  grid-template-rows: 60rem; }

.aboutUsMedia .slick-dotted.slick-slider {
  margin-bottom: 0; }

.aboutUsMedia .slick-dots {
  display: flex;
  width: auto;
  bottom: 2rem;
  left: calc(50% + 4.5rem); }

.aboutUsMedia__content {
  padding-top: 6rem;
  color: #2C2C2C; }

.aboutUsMedia__label {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 2.25rem; }

.aboutUsMedia__title {
  font-family: "Lora", sans-serif;
  font-size: 3.4rem;
  font-weight: bold;
  color: #212121;
  margin-bottom: 4rem; }

.aboutUsMedia__description {
  font-size: 1.6rem;
  line-height: 2.8rem; }

.spesial__grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center; }

.spesial__item {
  width: calc(100%/12 * 3 - 30px);
  margin-bottom: 30px;
  height: 50rem;
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid rgba(44, 44, 44, 0.3);
  border-radius: 4px;
  padding: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  line-height: 2.4rem;
  color: #2C2C2C; }

.spesial__item:hover {
  background-color: #ffc40c;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5), 0 15px 20px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid transparent; }

.spesial__item:hover .spesial__title::before {
  background-color: #2C2C2C; }

.spesial__item:hover .spesial__descripton {
  opacity: 1; }

.spesial__title {
  margin-bottom: 2.5rem;
  position: relative;
  font-size: 2rem;
  line-height: 2.4rem; }

.spesial__title::before {
  content: '';
  display: block;
  height: 4px;
  width: 35px;
  background-color: #FFC40C;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  top: -35px; }

.spesial__descripton {
  opacity: 0;
  -webkit-transition: opacity .3s linear;
  transition: opacity .3s linear;
  font-size: 1.4rem;
  line-height: 2.4rem; }

.preparation__grid {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
  color: #2C2C2C;
  font-size: 16px;
  line-height: 28px;
  padding-bottom: 10rem; }

.preparation__left {
  -ms-grid-column-span: 5;
  -ms-grid-column: 2;
  grid-column: 2/span 5;
  padding-right: 10px; }

.preparation__right {
  -ms-grid-column-span: 5;
  -ms-grid-column: 7;
  grid-column: 7/span 5;
  padding-left: 10px; }

.trialRegister {
  background-color: #8858A0;
  padding-top: 6.5rem;
  padding-bottom: 3rem;
  color: #fff;
  position: relative; }

.trialRegister::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); }

.trialRegister__grid {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px; }

.trialRegister__content {
  grid-column: 2/-1;
  max-width: 534px; }

.trialRegister__title {
  font-family: Lora;
  font-size: 3.6rem;
  font-weight: bold;
  margin-bottom: 2rem; }

.trialRegister__description {
  opacity: .8;
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-bottom: 3rem; }

#trial_les_form_id span.wpcf7-not-valid-tip {
  color: #000;
  font-size: 1.6em;
  padding-top: 0.5em;
  padding-left: 2.5em;
  background-color: #fff;
  border-radius: 50px;
  padding: 2px 10px;
  position: absolute;
  top: -8px;
  left: 15px; }

#trial_les_form_id span.wpcf7-not-valid-tip::after {
  content: '';
  display: block;
  height: 7px;
  width: 7px;
  background-color: #fff;
  transform: rotate(45deg);
  position: absolute;
  bottom: -3px;
  left: 18px; }

#trial_les_form_id div.wpcf7-validation-errors,
div.wpcf7-mail-sent-ok {
  border: none;
  font-size: 11px;
  position: absolute;
  bottom: 0;
  margin: 0; }

#trial_les_form_id form p {
  display: grid;
  grid-template-columns: minmax(230px, 365px) minmax(230px, 365px) min-content;
  grid-template-rows: 6rem;
  grid-gap: 2.5rem; }

#trial_les_form_id form p span {
  grid-row: 1; }

#trial_les_form_id form p input {
  grid-row: 1;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 27.5px;
  background-color: transparent;
  padding: 2rem 4.5rem;
  font-size: 1.6rem;
  color: white;
  transition: all .25s; }

#trial_les_form_id form p input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5); }

#trial_les_form_id form p input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5); }

#trial_les_form_id form p input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5); }

#trial_les_form_id form p input[type='submit'] {
  color: #212121;
  font-size: 1.8rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  background-color: #ffc40c;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
  padding: 1.8rem 6rem;
  border: none;
  transition: all .25s;
  cursor: pointer; }

#trial_les_form_id form p input[type='submit']:hover {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  transform: translateY(2px); }

.gallery {
  background-color: #8858A0;
  padding-top: 24rem;
  text-align: center;
  color: #fff; }

.gallery__title {
  font-family: "Lora", sans-serif;
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 35px; }

.gallery__description {
  font-size: 16px;
  padding-bottom: 37rem; }

.galleryMedia {
  color: #fff;
  position: relative;
  top: -30rem;
  margin-bottom: -20rem; }

.gallery__grid {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 45rem));
  grid-auto-rows: 45rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  grid-gap: 2.5rem;
  text-align: left; }

.gallery__item {
  padding: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-radius: 4px;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  position: relative;
  overflow: hidden; }

.gallery__item:hover .gallery__itemImg {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%); }

.gallery__item::after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 1%, rgba(0, 0, 0, 0.45) 30%, rgba(0, 0, 0, 0) 100%);
  transition: all .3s linear; }

.gallery__itemImg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all .3s linear;
  transition: all .3s linear; }

.gallery__item:hover::after {
  background: rgba(255, 196, 12, 0.92);
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.45); }

.gallery__item:hover .gallery__itemTitle,
.gallery__item:hover .gallery__itemDescription {
  color: #2C2C2C; }

.gallery__item:hover .gallery__itemTitle::after {
  opacity: 1;
  -webkit-transform: translateX(40px);
  -ms-transform: translateX(40px);
  transform: translateX(40px); }

.gallery__text {
  z-index: 2;
  transition: all .3s linear; }

.gallery__itemTitle {
  font-size: 2.6rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  position: relative; }

.gallery__itemTitle::after {
  content: url("../knowledge/img/icons/arrow_icon.svg");
  position: absolute;
  right: -20px;
  top: 4px;
  opacity: 0;
  -webkit-transition: all .3s linear;
  transition: all .3s linear; }

.gallery__itemDescription {
  font-size: 1.8rem;
  opacity: .8; }

.eventsReport {
  color: #fff;
  padding-bottom: 33rem;
  background-color: #8858A0;
  margin-top: 100px;
  padding-top: 7rem; }

.backLink {
  color: black;
  font-size: 1.8rem;
  font-weight: 500;
  position: relative;
  left: 32px; }

.backLink::before {
  content: url("../knowledge/img/icons/arrow_icon.svg");
  -webkit-transform: rotateZ(180deg);
  -ms-transform: rotate(180deg);
  transform: rotateZ(180deg);
  display: inline-block;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  position: absolute;
  left: -40px;
  top: -5px; }

.eventsReport__title {
  font-family: "Lora", sans-serif;
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
  margin-top: 12rem; }

.eventsReport__description {
  margin-top: 4.5rem;
  font-size: 1.6rem;
  line-height: 2.8rem;
  max-width: 760px;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }

.eventsReportMedia {
  position: relative;
  top: -26rem;
  margin-bottom: -20rem; }

.eventsReportMedia__grid {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 320px));
  justify-content: center;
  grid-auto-rows: 36rem;
  grid-gap: 2rem; }

.eventsReportMediaItemWrapper {
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  transition: all .3s ease; }

.eventsReportMediaItemWrapper > div {
  width: 100% !important;
  min-width: 100% !important;
  height: 100%; }

.eventsReportMediaItemWrapper:hover .eventsReportMedia__item {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%); }

.eventsReportMediaItemWrapper:hover::after {
  background: rgba(255, 196, 12, 0.92); }

.eventsReportMediaItemWrapper.mz-video:hover::before {
  opacity: 1; }

.eventsReportMediaItemWrapper:hover::before {
  opacity: 1; }

.eventsReportMedia__item {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  object-fit: cover;
  transition: all .3s ease; }

.eventsReportMediaItemWrapper::after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: transparent;
  transition: background .2s ease-in; }

.eventsReportMediaItemWrapper:before,
.eventsReportMediaItemWrapper.mz-video::before {
  content: url("./img/icons/search_icon.svg");
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 0;
  transition: opacity .2s ease-in; }

.eventsReportMediaItemWrapper.mz-video::before {
  background-image: url("./img/icons/icon_play_gallery.svg");
  content: '';
  background-size: 30px 30px;
  opacity: 1;
  width: 30px;
  height: 30px; }

.blog {
  margin-top: 100px; }

.blog__title {
  text-align: center;
  font-family: "Lora", sans-serif;
  font-size: 5rem;
  font-weight: bold;
  color: #2C2C2C;
  padding-top: 9rem;
  margin-bottom: 9rem; }

.blog__grid {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 320px));
  justify-content: center;
  grid-auto-rows: 45rem;
  grid-gap: 2rem;
  color: #fff;
  margin-bottom: 10rem; }

.blog__item {
  transition: all .2s ease-in;
  position: relative;
  padding: 35px; }

.blog__item > * {
  position: relative;
  z-index: 1; }

.blog__item::after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 1%, rgba(0, 0, 0, 0.45) 30%, rgba(0, 0, 0, 0) 100%);
  transition: background .2s ease-in; }

.blog__item--img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 0; }

.blog__item:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.35);
  color: #2c2c2c; }

.blog__item:hover::after {
  background: rgba(255, 196, 12, 0.92); }

.blog__item:hover .blog__arrow {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0); }

.blog__item:hover .blog__category::after {
  background: #2C2C2C; }

.blog__category {
  text-transform: uppercase;
  margin-bottom: 35px;
  position: relative;
  font-size: 1.4rem; }

.blog__category::after {
  content: '';
  display: block;
  height: 3px;
  width: 50px;
  background: #fff;
  position: absolute;
  bottom: -10px;
  -webkit-transition: background 0.3s linear;
  transition: background 0.3s linear; }

.blog__itemTitle {
  font-family: "Lora", sans-serif;
  font-size: 2.8rem;
  font-weight: bold;
  max-width: 400px;
  margin-bottom: 15px; }

.blog__itemPostDate {
  opacity: .8;
  font-size: 1.4rem; }

.blog__arrow {
  position: absolute;
  bottom: 35px;
  left: 35px;
  opacity: 0;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: opacity .3s linear, -webkit-transform .3s linear;
  transition: opacity .3s linear, -webkit-transform .3s linear;
  transition: opacity .3s linear, transform .3s linear;
  transition: opacity .3s linear, transform .3s linear, -webkit-transform .3s linear; }

.blogArticle {
  margin-top: 100px;
  padding-bottom: 9rem; }

.blogArticle__header {
  background: url("../img/main_laboutcompany_slider_01.jpg") center center no-repeat;
  background-size: cover;
  color: #fff;
  padding-top: 30%;
  padding-bottom: 50px;
  position: relative; }

.blogArticle__header > * {
  position: relative;
  z-index: 2; }

.blogArticle__header::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 26%, rgba(0, 0, 0, 0) 53%, rgba(0, 0, 0, 0.32) 100%);
  z-index: 1; }

.blogArticle .backLink {
  display: inline-block;
  margin: 3rem 0; }

.blogArticle .blog__itemTitle {
  max-width: 720px;
  font-size: 3.6rem; }

.blogArticle__header--text {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px; }

.blogArticle__content {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
  padding-top: 7.5rem;
  font-size: 1.6rem;
  line-height: 2.8rem; }

.blogArticle__content a {
  color: #8858A0; }

.articleFooter {
  margin-top: 4rem;
  border-top: 1px solid rgba(33, 33, 33, 0.3);
  padding-top: 4rem; }

.articleFooter__label {
  color: #212121;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem; }

.vacancies {
  margin-top: 100px;
  padding-top: 9rem;
  color: #2C2C2C; }

.vacancies__title {
  font-family: "Lora", sans-serif;
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;
  font-size: 36px; }

.vacancies__description {
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35px; }

.vacancy {
  max-width: calc(100%/12 * 8 - 0px);
  padding-bottom: 5rem;
  margin-bottom: 7rem;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3); }

.vacancy__header {
  background-color: #8858A0;
  border-radius: 4px;
  color: #fff;
  padding-top: 5rem;
  padding-bottom: 3rem;
  padding-left: 10%;
  position: relative; }

.vacancy__title {
  font-size: 3.2rem;
  font-weight: 500;
  margin-bottom: 1.5rem; }

.vacancy__price {
  font-size: 4.2rem;
  font-weight: 300; }

.vacancy__currency {
  font-size: 1.8rem; }

.vacancy__body {
  padding-top: 5.5rem;
  padding-left: 10%;
  padding-right: 10%;
  font-size: 1.6rem;
  line-height: 2.8rem; }

.vacancy__button {
  position: absolute;
  bottom: -24px;
  right: 75px;
  color: #212121;
  background-color: #FFC40C;
  font-weight: 500; }

.breadCrumbs {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: bold;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 7.5rem;
  margin-bottom: 6rem; }

.breadCrumbs__link.active,
.breadCrumbs__link:hover {
  opacity: .6; }

.breadCrumbs__item:last-child {
  opacity: .6; }

.artSubsection {
  color: #fff;
  position: relative;
  margin-top: 100px; }

.artSubsection .breadCrumbs {
  grid-column: 2/-1; }

.artSubsection__title {
  grid-column: 2/-1;
  margin-bottom: 3rem;
  font-family: "Lora", sans-serif;
  font-size: 5rem;
  font-weight: bold; }

.artSubsection__description {
  grid-column: 2/-1;
  grid-row: 3;
  opacity: .8;
  font-size: 1.6rem;
  line-height: 2.8rem;
  margin-bottom: 3.5rem;
  max-width: 570px; }

.artServisesList {
  max-width: 460px;
  margin-bottom: 9.5rem;
  -ms-grid-column-span: 4;
  -ms-grid-column: 2;
  grid-column: 2/span 4; }

.artServisesList__item {
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding-top: 3.5rem; }

.artServisesList__item::after {
  content: '';
  position: absolute;
  width: 0;
  display: block;
  height: 3px;
  background-color: white;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out; }

.artServisesList__item:hover .artServisesList__arrow {
  opacity: 1; }

.artServisesList__item:hover::after {
  width: 100%; }

.servicesAge {
  opacity: 0.5;
  font-size: 1.8rem;
  margin-top: 7px;
  margin-bottom: 2rem;
  display: inline-block;
  font-weight: 100; }

.artServisesList__link {
  font-family: "Lora", sans-serif;
  font-size: 3.4rem;
  font-weight: bold;
  display: block; }

.artServisesList__age {
  display: block;
  font-size: 1.8rem;
  font-family: "Roboto", sans-serif;
  opacity: .5;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-weight: normal; }

.artServisesList__arrow {
  position: absolute;
  right: 0;
  top: 45px;
  opacity: 0;
  -webkit-transition: opacity .3s ease-in-out;
  transition: opacity .3s ease-in-out; }

.artSubsection .trialRegister__description {
  max-width: 534px; }

.artSubsection__contentWrapper {
  position: relative; }

.artSubsection__bgImage {
  grid-column: 6/-1;
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
  grid-row: 3/span 2;
  position: relative;
  right: -10vw; }

.trialRegister__wrapper {
  grid-column: 2/-1;
  z-index: 1; }

.standart_page {
  margin-top: 100px;
  padding-bottom: 12rem; }

.standart_page p {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.8rem;
  text-align: justify;
  margin-right: calc(100%/12 * 2 - 0px);
  margin-left: calc(100%/12 * 2 - 0px); }

.rules__grid {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px; }

.rules__list {
  grid-column: 4/-2;
  margin-left: 0;
  padding-right: 0;
  list-style-type: none; }

.rules__title {
  font-family: "Lora", sans-serif;
  color: #2C2C2C;
  font-size: 3.6rem;
  font-weight: bold;
  text-align: center;
  padding-top: 9rem;
  margin-bottom: 9rem; }

.rules__listItem {
  font-size: 1.8rem;
  max-width: 820px;
  margin-bottom: 4rem;
  font-weight: 500;
  counter-increment: step-counter;
  position: relative; }

.rules__listItem::before {
  content: counter(step-counter);
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: -7rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 2.4rem;
  background-color: #8858A0;
  font-family: "Lora", sans-serif;
  color: #fff;
  border-radius: 3px;
  height: 5rem;
  width: 5rem; }

.standart_page_trial_wrapper {
  background-color: #8858A0;
  height: 10rem; }

.standart_page_trial_wrapper #trial {
  visibility: hidden; }

.manuals {
  margin-top: 100px;
  padding-top: 9rem;
  padding-bottom: 400px;
  background-color: #8858A0;
  color: #fff; }

.manuals__title {
  font-family: "Lora", sans-serif;
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5rem; }

.manuals__description {
  font-size: 1.8rem;
  line-height: 2.8rem;
  text-align: center;
  max-width: 745px;
  margin-left: auto;
  margin-right: auto; }

.manualsMedia {
  position: relative;
  top: -30rem;
  margin-bottom: -25rem;
  color: #2C2C2C; }

.manualsMedia__item {
  border: 1px solid #E2E2E2;
  border-radius: 4px;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15);
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 55rem;
  margin-bottom: 5rem; }

.manualsMedia__slider {
  background-color: #fff;
  display: flex;
  align-items: center;
  position: relative; }

.manualsMedia__description {
  background-color: #F5F5F5;
  padding: 5rem;
  position: relative; }

.manualsMedia__item .slick-dotted.slick-slider,
.costumesMedia__item .slick-dotted.slick-slider {
  margin-bottom: 0; }

.manualsMedia__slider .slick-dots,
.costumesMedia__slider .slick-dots {
  height: auto;
  bottom: 1rem;
  left: 0; }

.manualsMedia__slider .slick-next,
.costumesMedia__slider .slick-next {
  right: .5rem; }

.manualsMedia__slider .slick-prev,
.costumesMedia__slider .slick-prev {
  left: .5rem;
  z-index: 1; }

.manualMedia__item--title {
  font-family: "Lora", sans-serif;
  max-width: 500px;
  font-size: 3.4rem;
  font-weight: bold;
  line-height: 4.3rem;
  margin-bottom: 4rem; }

.manualsMedia__item--description {
  font-size: 1.6rem;
  line-height: 2.8rem; }

.manualMedia__item--button,
.costumesMedia__item--button {
  background-color: #FFC40C;
  color: #212121;
  font-size: 1.8rem;
  font-weight: bold;
  position: absolute;
  bottom: 3rem;
  left: 3rem; }

.manualsMedia__item--currency {
  font-weight: 500; }

.manualsMedia__slider--image {
  object-fit: cover; }

.hireCostumes {
  padding-top: 12rem;
  padding-bottom: 35rem;
  background-color: #8858A0;
  color: #fff; }

.hireCostumes__title {
  font-family: "Lora", sans-serif;
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5rem; }

.hireCostumes__description {
  font-size: 1.6rem;
  line-height: 2.8rem;
  text-align: center;
  max-width: 745px;
  margin-left: auto;
  margin-right: auto; }

.costumesMedia {
  position: relative;
  top: -30rem;
  margin-bottom: -20rem;
  color: #2C2C2C; }

.costumesMedia__grid {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 5rem; }

.costumesMedia__item {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 55rem;
  border: 1px solid #E2E2E2;
  border-radius: 4px;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15); }

.costumesMedia__slider {
  background-color: #fff;
  padding: 5rem 7rem;
  display: flex;
  align-items: center;
  position: relative; }

.costumesMedia__description {
  background-color: #F5F5F5;
  padding: 5.5rem;
  position: relative; }

.costumesMedia__item--title {
  font-family: "Lora", sans-serif;
  max-width: 500px;
  font-size: 3.4rem;
  font-weight: bold;
  line-height: 4.3rem;
  margin-bottom: 4rem; }

.costumesMedia__item--description {
  font-size: 1.6rem;
  line-height: 2.8rem; }

.costumesMedia__item--currency {
  font-weight: 500; }

#map {
  height: 754px;
  width: 100%; }

.error {
  height: 100vh;
  width: 100vw;
  color: #2C2C2C; }

.error .container {
  height: 100%; }

.error__content {
  height: 100%;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-gap: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.error__title {
  color: #8858A0;
  font-family: "Lora", sans-serif;
  font-size: 200px;
  font-weight: bold;
  -ms-grid-column-span: 8;
  -ms-grid-column: 2;
  grid-column: 2/span 8;
  -ms-grid-row: 1;
  grid-row: 1; }

.error__box {
  -ms-grid-row: 1;
  grid-row: 1;
  -ms-grid-column-span: 8;
  -ms-grid-column: 8;
  grid-column: 8/span 8;
  margin-top: 285px;
  padding-left: 20px; }

.error__text {
  font-family: "Lora", sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px; }

.error__description {
  font-size: 14px;
  margin-bottom: 35px; }

.homePageLink {
  display: inline-block;
  padding: 20px 75px;
  color: #212121;
  font-size: 18px;
  font-weight: 500;
  background-color: #FFC40C;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15); }

.error__img {
  -ms-grid-row: 1;
  grid-row: 1;
  grid-column: 15/-1;
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end; }

#reviews {
  padding-bottom: 185px; }

.reviewsSlider__container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  visibility: hidden; }

.reviews__title {
  padding-top: 50px;
  margin-bottom: 0; }

.reviewsSlider .item.slick-current.slick-active {
  background-color: #FFC40C; }

.reviewsSlider .item {
  border-radius: 4px;
  background-color: rgba(224, 224, 224, 0.3);
  height: 470px;
  padding: 70px 85px 0 85px;
  position: relative;
  margin-right: 50px;
  margin-left: 50px; }

.reviewsSlider .title {
  color: #212121;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px; }

.reviewsSlider .position {
  color: rgba(33, 33, 33, 0.4);
  font-size: 14px;
  text-align: center;
  margin-bottom: 40px; }

.reviewsSlider .description {
  max-width: 766px;
  font-family: 'Lora', sans-serif;
  font-size: 22px;
  font-style: italic;
  line-height: 40px;
  text-align: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  top: -60px;
  display: flex;
  align-items: center; }

.reviewsSlider .description::after {
  content: "„";
  font-family: 'Lora', sans-serif;
  font-size: 150px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 80px; }

.reviewsSlider .review_img {
  height: 88px;
  width: 88px;
  border-radius: 50%;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  border: 8px solid #fff; }

.reviewsSlider .slick-track {
  padding-top: 115px; }

.reviewsSlider .slick-next {
  right: calc(50% - 540px); }

.reviewsSlider .slick-prev {
  left: calc(50% - 540px); }

.reviewsSlider .slick-arrow {
  top: calc(50% + 50px);
  z-index: 1; }

.container-grid.filter {
  grid-row: 1/-1;
  padding-left: 0;
  padding-right: 0; }

.arrow_dropdown {
  display: none;
  transition: transform .3s ease; }

.filter-list {
  display: flex;
  justify-content: space-between;
  height: 8rem; }

.filter-item {
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  font-size: 2.6rem;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  color: rgba(255, 255, 255, 0.3); }

.slide-fade-enter-active {
  transition: all .3s ease; }

.slide-fade-leave-active {
  transition: all .8s ease; }

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-100px);
  opacity: 0; }

.age {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3.6rem;
  font-weight: bold; }

.age span {
  font-size: 1.5rem;
  font-weight: 300;
  margin-top: 2px; }

.modal__header {
  background-color: #F5F5F5;
  padding: 3rem 11rem 3rem 11rem; }

.modal__title {
  color: #212121;
  font-size: 2.6rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.5rem; }

.modal__description {
  text-align: center;
  color: #2C2C2C;
  font-size: 1.6rem;
  line-height: 2.8rem;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto; }

.modal__body {
  padding: 2rem 11rem 3rem 11rem; }

.modal__label {
  color: #2C2C2C;
  opacity: 0.5;
  font-size: 1.4rem;
  display: inline-block;
  margin-bottom: 2rem;
  margin-top: 1rem; }

.modal__inform--wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.modal__inform--wrapper br {
  display: none; }

.modal__inform--wrapper span {
  width: calc(50% - 2rem);
  margin-bottom: 2rem;
  display: block; }

.modal__inform--wrapper span:first-child {
  display: none; }

.modal__input {
  border-radius: 4px;
  border: 1px solid rgba(33, 33, 33, 0.3);
  height: 5.5rem;
  display: flex;
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.9rem;
  width: 100%; }

.modal__input:focus {
  border: 2px solid #8858A0;
  color: #8858A0; }

.modal__price--wrapper p {
  margin-bottom: 2rem; }

.modal__textarea {
  grid-row: 2;
  grid-column: 1/-1;
  resize: none;
  padding: 1.5rem 3rem;
  height: 15rem;
  font-family: "Roboto", sans-serif;
  font-weight: 300; }

.modal__file--wrapper {
  grid-row: 3;
  grid-column: 1/-1;
  height: 7rem;
  display: flex;
  position: relative;
  border: 1px solid rgba(174, 174, 174, 0.5);
  border-radius: 4px;
  background-color: #F5F5F5; }

.modal__file--wrapper > p:last-child,
.modal__file--wrapper > p:last-child span {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.modal__fileUpload {
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.modal__fileUpload--title {
  color: #212121;
  font-size: 1.8rem;
  font-weight: 500;
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0 !important; }

.modal__fileUpload--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #8858A0;
  width: 7rem;
  height: 6.8rem;
  font-size: 4rem;
  margin-top: -0.1rem;
  height: 7rem; }

.modal__fileUpload--info {
  display: inline-block;
  opacity: 0.5;
  color: #2C2C2C;
  font-size: 1.2rem;
  margin-top: 5px;
  font-weight: 100; }

.modal__button {
  position: absolute;
  bottom: -30px;
  background-color: #FFC40C;
  color: #212121;
  left: 50%;
  transform: translateX(-50%); }

.exp-salary-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem; }

.exp-salary-container span {
  width: calc(50% - 2rem); }

#schedule-price-form-popup,
#manuals-form-popup,
#prokat-form-popup,
.questionnaire-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding-left: 5rem;
  padding-right: 5rem;
  z-index: 3; }

#schedule-price-form-popup.open,
#manuals-form-popup.open,
#prokat-form-popup.open,
.questionnaire-popup.open {
  display: flex; }

.questionnaire-popup div.wpcf7-mail-sent-ok {
  bottom: 30px;
  margin: 0;
  left: 50%;
  transform: translateX(-50%); }

#schedule-price-form-popup form p span:first-child,
#manuals-form-popup form p span:first-child,
#prokat-form-popup form p span:first-child {
  display: none; }

.popup-close {
  background: transparent;
  color: #fff;
  border: none;
  outline: none;
  font-size: 5rem;
  position: absolute;
  right: -5rem;
  top: -5rem;
  cursor: pointer; }

#schedule-price-form-popup input[type='submit'],
#manuals-form-popup input[type='submit'],
#prokat-form-popup input[type='submit'],
.questionnaire-popup input[type='submit'] {
  background-color: #FFC40C;
  height: auto;
  border-radius: 3rem;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
  padding: 2rem 5rem;
  color: #000;
  font-size: 1.8rem;
  outline: none;
  border: none;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  width: auto;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: 0;
  transition: all .3s ease; }

#schedule-price-form-popup input[type='submit']:hover,
#manuals-form-popup input[type='submit']:hover,
#prokat-form-popup input[type='submit']:hover,
.questionnaire-popup input[type='submit']:hover {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  transform: translate(-50%, 2px); }

#schedule-price-form-popup .form-wrapper,
#manuals-form-popup .form-wrapper,
#prokat-form-popup .form-wrapper,
.questionnaire-popup .form-wrapper {
  position: relative;
  max-width: 460px;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.4);
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 6rem;
  transform: translateY(-200%);
  transition: transform .5s ease; }

.questionnaire-popup .form-wrapper {
  max-width: 940px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  z-index: 4; }

#schedule-price-form-popup .form-wrapper.open,
#manuals-form-popup .form-wrapper.open,
#prokat-form-popup .form-wrapper.open {
  transform: translateY(0); }

.questionnaire-popup .form-wrapper.open {
  transform: translateY(2vh); }

#schedule-price-form-popup .form-title,
#manuals-form-popup .form-title,
#prokat-form-popup .form-title {
  background-color: #F5F5F5;
  font-size: 2.6rem;
  font-weight: 500;
  color: #212121;
  padding: 4.5rem;
  margin-bottom: 2.5rem;
  text-align: center;
  margin-left: -4rem;
  margin-right: -4rem; }

#schedule-price-form-popup input,
#manuals-form-popup input,
#prokat-form-popup input,
#manuals-form-popup textarea,
#prokat-form-popup textarea,
#schedule-price-form-popup textarea {
  height: 5.5rem;
  border: 1px solid rgba(33, 33, 33, 0.5);
  border-radius: 4px;
  margin-bottom: 10px;
  width: 100%;
  padding-left: 3rem;
  font-family: Roboto, sans-serif;
  font-size: 16px; }

#schedule-price-form-popup textarea,
#manuals-form-popup textarea,
#prokat-form-popup textarea {
  height: 15rem;
  resize: none;
  padding: 3rem;
  padding-top: 1.5rem;
  opacity: .7; }

#schedule-price-form-popup textarea:focus,
#manuals-form-popup textarea:focus,
#prokat-form-popup textarea:focus {
  opacity: 1; }

#schedule-price-form-popup input::-webkit-input-placeholder,
#manuals-form-popup input::-webkit-input-placeholder,
#prokat-form-popup input::-webkit-input-placeholder {
  opacity: .7; }

#schedule-price-form-popup input::-moz-placeholder,
#manuals-form-popup input::-moz-placeholder,
#prokat-form-popup input::-moz-placeholder {
  opacity: .7; }

#schedule-price-form-popup input:-ms-input-placeholder,
#manuals-form-popup input:-ms-input-placeholder,
#prokat-form-popup input:-ms-input-placeholder {
  opacity: .7; }

#schedule-price-form-popup input:focus,
#manuals-form-popup input:focus,
#prokat-form-popup input:focus {
  border: 1px solid #212121;
  font-size: 1.6rem;
  color: #000; }

#schedule-price-form-popup input[type='submit']:focus,
#manuals-form-popup input[type='submit']:focus,
#prokat-form-popup input[type='submit']:focus {
  border: none; }

#schedule-price-form-popup form > p,
#manuals-form-popup form > p,
#prokat-form-popup form > p {
  display: flex;
  flex-direction: column; }

#schedule-price-form-popup form span.ajax-loader.is-active,
#manuals-form-popup form span.ajax-loader.is-active,
#prokat-form-popup form span.ajax-loader.is-active,
.questionnaire-popup form span.ajax-loader.is-active {
  display: none; }

#schedule-price-form-popup form span,
#manuals-form-popup form span,
#prokat-form-popup form span {
  width: 100%;
  position: relative; }

#schedule-price-form-popup span.wpcf7-not-valid-tip,
#manuals-form-popup span.wpcf7-not-valid-tip,
#prokat-form-popup span.wpcf7-not-valid-tip,
.questionnaire-popup span.wpcf7-not-valid-tip {
  color: #000;
  background: #FFC40C;
  font-size: 1.2rem;
  position: absolute;
  padding: .5rem 2rem;
  border-radius: 10px;
  width: auto;
  top: -1rem;
  left: 1.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); }

#schedule-price-form-popup span.wpcf7-not-valid-tip::after,
#manuals-form-popup span.wpcf7-not-valid-tip::after,
#prokat-form-popup span.wpcf7-not-valid-tip::after,
.questionnaire-popup span.wpcf7-not-valid-tip::after {
  content: '';
  display: inline-block;
  height: 5px;
  width: 5px;
  background: #FFC40C;
  transform: rotate(45deg);
  bottom: -2px;
  position: absolute;
  left: 2rem; }

#schedule-price-form-popup div.wpcf7-validation-errors,
#prokat-form-popup div.wpcf7-validation-errors,
#manuals-form-popup div.wpcf7-validation-errors,
.questionnaire-popup div.wpcf7-validation-errors,
#schedule-price-form-popup div.wpcf7-acceptance-missing,
#prokat-form-popup div.wpcf7-acceptance-missing,
#manuals-form-popup div.wpcf7-acceptance-missing,
.questionnaire-popup div.wpcf7-acceptance-missing {
  border: none;
  font-size: 1.5rem;
  position: absolute;
  bottom: 4.5rem;
  width: calc(100% - 8rem);
  margin: 0;
  padding: 0;
  text-align: center; }

.questionnaire-popup div.wpcf7-validation-errors,
.questionnaire-popup div.wpcf7-acceptance-missing {
  width: calc(100% - 22rem); }

@media (min-width: 1921px) {
  .ourWorkshop__button-wrapper {
    bottom: 25rem; } }

@media (min-width: 3000px) {
  .ourWorkshop__button-wrapper {
    bottom: 65rem; } }

@media (min-width: 1560px) {
  .mainSlider .item {
    height: 550px; }
  .mainSlider .item img {
    position: absolute;
    height: 120%;
    bottom: -120px;
    right: 50px; }
  .studioSubsection__body li::before {
    top: -12px; }
  #trial_les_form_id div.wpcf7-validation-errors,
  div.wpcf7-mail-sent-ok {
    bottom: 0px; } }

@media (max-width: 1550px) {
  html {
    font-size: 50%; }
  .container {
    max-width: 1100px; }
  .container-small {
    max-width: 1000px; }
  header {
    height: 70px; }
  .header__link {
    font-size: 16px; }
  .headerSlider {
    margin-top: 100px; }
  .mainSlider .tag {
    font-size: 14px;
    margin-bottom: 15px; }
  .mainSlider .title {
    font-size: 40px;
    line-height: 1.3;
    margin-bottom: 25px; }
  .mainSlider .description {
    font-size: 14px;
    line-height: 1.5; }
  .text-wrapper .button {
    font-size: 14px; }
  .ourServises__grid {
    grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
    grid-auto-rows: minmax(min-content, 350px); }
  .ourWorkshop__leftBox {
    margin-right: 40px;
    max-width: 500px;
    padding: 50px 0 20px 20px; }
  .video-link {
    padding: 0;
    padding-left: 5px; }
  #ourWorkshop .slick-dots {
    max-width: 500px;
    right: calc(50% + 20px); }
  .news__postTag {
    font-size: 13px; }
  .news__postTitle {
    font-size: 20px; }
  .contacts__social {
    grid-column: 11/span 2; }
  .contacts__inform {
    grid-column: 1/span 5; }
  #footer .container-small {
    max-width: 1100px; }
  .mainSlider .item {
    height: 450px; }
  .sectionTitle {
    padding-top: 35px;
    margin-bottom: 35px;
    font-size: 24px; }
  .ourServises__description {
    font-size: 16px; }
  .ourServises__item {
    padding: 25px; }
  .ourServises__itemTitle {
    font-size: 22px; }
  #ourServises {
    padding-bottom: 50px; }
  #trial {
    padding-bottom: 40px; }
  .trial__title {
    font-size: 26px; }
  .trial__description {
    font-size: 14px; }
  .trial__content {
    grid-column: 1/-1; }
  .contacts__itemTitle {
    font-size: 18px; }
  .contacts__inform .contacts__contactsItemWrapper {
    font-size: 14px; }
  .ourWorkshop__content {
    height: 490px !important; }
  .ourWorkshop__itemTitle {
    font-size: 26px;
    margin-bottom: 30px; }
  .ourWorkshop__itemDescription {
    font-size: 14px;
    line-height: 1.6; }
  .reviewsSlider .slick-track {
    padding-top: 45px; }
  .reviewsSlider .item {
    height: 400px; }
  .reviewsSlider .description {
    font-size: 20px;
    line-height: 30px; }
  #reviews {
    padding-bottom: 70px; }
  .reviewsSlider .slick-arrow {
    top: 50%; }
  .news__leftBox {
    grid-column: 1/span 8; }
  .news__rightBox {
    grid-column: 9/span 5; }
  footer {
    font-size: 14px; }
  footer .container-small {
    min-height: 45px; }
  .studioSubsection,
  .artSubsection,
  #aboutUs,
  .standart_page,
  .blog,
  .blogArticle,
  .manuals,
  .contacts-separate,
  #schedule-price-section,
  .vacancies,
  .eventsReport {
    margin-top: 70px; }
  .filter-list.stick.offsetTop {
    top: 70px; }
  .ourServises__itemTitle svg {
    top: -1rem; }
  .artServisesList__arrow {
    top: 32px; }
  .schedule-price-section__header {
    padding-bottom: 5rem; }
  .schedule-wrapper {
    margin-top: 2.5rem; }
  .schedule__points {
    font-size: 3rem; }
  .age {
    font-size: 3.6rem; }
  .price-wrapper .price {
    font-size: 4rem; }
  .gallery__grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 42rem));
    grid-auto-rows: 42rem; }
  .studioSubsection__header--content {
    grid-column: 2/span 6; }
  .artSubsection__bgImage {
    grid-column: 7/-1;
    right: 0; }
  .aboutUs__imgWrapper {
    grid-column: 7/-1; }
  .schedule {
    padding-bottom: 39rem; }
  .contacts__subscribe {
    grid-column: 6/span 4; } }

@media (max-width: 1200px) {
  .trial__content {
    grid-column: 1/-1; }
  .aboutUs__imgWrapper {
    margin-right: 0; } }

@media (max-width: 1180px) {
  .mainSlider .slick-arrow {
    z-index: 2; }
  .mainSlider .slick-next {
    top: calc(100% + 28px);
    right: 0; }
  .mainSlider .slick-prev {
    top: calc(100% + 26px);
    left: 0; }
  html {
    font-size: 43%; }
  .aboutUsMedia .slick-initialized .slick-slide {
    grid-template-rows: 68rem; } }

@media (max-width: 1130px) {
  .header__link,
  .header__phone {
    font-size: 14px; } }

@media (max-width: 1100px) {
  .reviewsSlider .description {
    max-width: 80vw; }
  .reviewsSlider .slick-arrow {
    top: calc(100% + 28px);
    right: 0; }
  .reviewsSlider .slick-prev {
    left: 0; } }

@media (max-width: 1060px) {
  .header__link,
  .header__phone {
    font-size: 13px; }
  .header__listItem {
    padding-left: 10px;
    padding-right: 10px; }
  .header__dropdownToggle {
    padding-right: 0; }
  #ourWorkshop .slick-dots {
    left: 20px; } }

@media (max-width: 1024px) {
  .ourWorkshop__leftBox {
    padding: 70px 0 30px 20px;
    font-size: 35px;
    margin-bottom: 30px; }
  .ourWorkshop__itemDescription {
    margin-bottom: 20px; }
  #ourWorkshop .slick-dots {
    right: calc(50% + -55px); }
  .ourWorkshop__itemTitle {
    font-size: 26px; }
  .ourWorkshop__button-wrapper {
    bottom: 60px; }
  .spesial__title {
    font-size: 2.3rem;
    line-height: 3rem; }
  .spesial__descripton {
    font-size: 2rem;
    line-height: 3rem; }
  .aboutUs__description {
    font-size: 2rem; } }

@media (max-width: 992px) {
  html {
    font-size: 38%; }
  .news__leftBox {
    grid-column: 1/span 6; }
  .news__rightBox {
    grid-column: 7/span 6; }
  .news__leftBoxContent {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(min-content, 350px); }
  .news__post:nth-child(3) {
    grid-column: 1; }
  .news__rightBox iframe {
    border: none !important; }
  .ourServises__item {
    padding: 25px; }
  .ourServises__itemTitle {
    font-size: 24px; }
  .ourServises__grid {
    grid-template-columns: repeat(auto-fill, minmax(218px, 1fr));
    grid-auto-rows: minmax(min-content, 265px); }
  .sectionTitle {
    padding-top: 50px;
    margin-bottom: 35px; }
  .contacts__phones {
    margin-right: auto; }
  #ourServises {
    padding-bottom: 50px; }
  #news {
    padding-bottom: 230px; }
  #reviews {
    padding-bottom: 100px; }
  .reviewsSlider .slick-track {
    padding-top: 75px; }
  #map {
    display: none; }
  #contacts .prefooter-contact-form {
    position: absolute;
    top: -180px;
    margin-bottom: -153px;
    left: 50%;
    grid-row: 1;
    grid-column: 1/-1;
    padding: 50px 50px 80px 50px;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px; }
  .studioSubsection,
  .artSubsection,
  #aboutUs,
  .standart_page,
  .blog,
  .blogArticle,
  .manuals,
  .contacts-separate,
  #schedule-price-section,
  .vacancies,
  .eventsReport {
    margin-top: 60px; }
  .blog__grid {
    margin-bottom: 40rem; }
  .vacancies {
    margin-bottom: 40rem; }
  .studioSubsection__footer--order {
    background: #FFC40C;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: calc(100%/12 + 20px); }
  .studioSubsection__footer {
    background: transparent; }
  .studioSubsection__footer .container-grid {
    display: flex;
    flex-direction: column; }
  .studioSubsection__footer--prices {
    padding-bottom: 220px;
    background: #8858A0;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: calc(100%/12 + 20px); }
  .studioSubsection__footer--pricesLink {
    padding: 1.15rem 3rem;
    height: 45px; }
  .trial-wrapper {
    padding-bottom: 150px; }
  .aboutUs__imgWrapper {
    grid-column: 8/-1;
    grid-row: 1; }
  .aboutUsMedia .slick-initialized .slick-slide {
    grid-template-rows: 75rem; }
  .standart_page_trial_wrapper {
    padding-bottom: 160px; }
  .subsection-text {
    padding-bottom: 23rem; }
  .galleryMedia {
    margin-bottom: 10rem; }
  .eventsReportMedia {
    margin-bottom: 15rem; }
  .schedule-price-section__body {
    padding-bottom: 30rem; }
  .schedule {
    padding-bottom: 50rem; }
  .costumesMedia {
    margin-bottom: 10rem; }
  .ourServises__separate {
    margin-bottom: 30rem; }
  .studioSubsection__description {
    font-size: 15px;
    line-height: 1.7; }
  .studioSubsection__body {
    font-size: 15px;
    line-height: 1.7; }
  #contact-page-body #map {
    display: block; }
  .studioSubsection__body li::before {
    top: -2px; }
  .contacts__inform {
    grid-column: 2/span 4; } }

@media (max-width: 970px) {
  .contacts__inform {
    grid-column: 1/span 4; }
  .contacts__subscribe {
    grid-column: 5/span 4; }
  .contacts__address {
    top: 10px; } }

@media (max-width: 920px) {
  .headerSlider {
    margin-top: 80px; }
  .header__nav {
    position: fixed;
    background-color: #fff;
    color: #000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    transform: translateX(100%);
    transition: transform .3s ease-in-out;
    height: 100vh;
    overflow: hidden; }
  .header__dropdownItem {
    width: 100%; }
  .header__nav .header__list {
    padding-top: 50px;
    height: 100vh;
    overflow: hidden; }
  .header__list {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    padding-left: 5%; }
  .header__listItem {
    margin-bottom: 22px;
    height: auto;
    width: 100%; }
  .header__listItem.menu_text_title {
    display: block; }
  .hamburger-icon {
    display: flex; }
  .header__link {
    font-size: 18px; }
  header {
    height: 60px; }
  .header__logoWrapper {
    width: 100%;
    justify-content: space-between; }
  .header__logo {
    height: 50px;
    width: 50px; }
  .header__dropdown {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transform: translateX(100%);
    z-index: 2; }
  .header__dropdown.open {
    transform: translateX(0);
    padding-left: 2%;
    overflow-y: auto;
    overflow-x: hidden; }
  .header__link {
    font-weight: bold;
    font-size: 20px;
    height: auto; }
  .header__dropdownItem.education > a svg,
  .header__dropdownItem.art > a svg,
  .header__dropdownItem.individual > a svg {
    display: none; }
  .header__dropdown .header__dropdownItem:hover {
    background: transparent;
    color: #000; }
  .header__listItem.active,
  .header__listItem:hover {
    border-color: transparent;
    background-color: transparent; }
  .header__listItem .shevrone_icon {
    transform: rotate(0); }
  .header__listItem.header__dropdownToggle {
    transition: all .3s ease-in-out; }
  .header__listItem.header__dropdownToggle > .header__link {
    display: flex; }
  .header__dropdown2 {
    position: static;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transform: translateX(10px);
    transition: none;
    box-shadow: none; }
  .header__dropdown2 .header__link {
    font-weight: normal;
    padding: 5px 25px; }
  .header__dropdown2 .header__dropdownItem:last-child a {
    margin-bottom: 20px; }
  .header__dropdown2.education,
  .header__dropdown2.art,
  .header__dropdown2.individual {
    position: static;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  .header__dropdown2.education .header__dropdownItem a:hover,
  .header__dropdown2.art .header__dropdownItem a:hover,
  .header__dropdown2.individual .header__dropdownItem a:hover {
    background: transparent;
    color: #000; }
  .header__dropdownItem:hover .header__link svg,
  .header__dropdownItem.art:hover .shevrone_icon {
    fill: #000; }
  .header__list svg {
    transition: transform .1s ease-in-out; }
  .header__listItem.header__dropdownToggle .header__link svg.down,
  .header__dropdownItem.education .header__link svg.down,
  .header__dropdownItem.art .header__link svg.down,
  .header__dropdownItem.individual .header__link svg.down {
    transform: rotate(90deg); }
  .header__dropdownItem.education {
    margin-top: 50px; }
  .mainSlider .title {
    font-size: 26px; }
  .text-wrapper .button {
    font-size: 12px;
    padding: 15px 35px;
    bottom: 20px; }
  .mainSlider .description {
    font-size: 13px; }
  .mainSlider .item {
    padding-left: 30px;
    height: 340px; }
  #ourWorkshop .slick-initialized .slick-slide {
    grid-template-columns: 1fr;
    grid-template-rows: min-content 265px; }
  .ourWorkshop__content {
    height: auto !important; }
  .ourWorkshop__leftBox {
    padding: 20px;
    justify-self: start;
    max-width: 100%;
    margin-bottom: 0;
    margin-right: 0; }
  .ourWorkshop__itemTitle {
    margin-bottom: 15px; }
  .ourWorkshop__itemDescription {
    max-width: 100%; }
  .ourWorkshop__detail-link {
    margin-bottom: 0; }
  .contacts__itemTitle {
    margin-bottom: 20px;
    margin-top: 25px; }
  #ourWorkshop .slick-dots {
    max-width: 100%;
    left: 0;
    justify-content: center;
    bottom: -40px; }
  .reviews__title {
    padding-top: 70px; }
  .ourWorkshop__button-wrapper {
    bottom: -3rem; }
  .spesial__item {
    width: calc(100%/12 * 5 - 30px); }
  .aboutUsMedia .slick-initialized .slick-slide {
    grid-template-rows: 85rem; }
  .hireCostumes__description {
    font-size: 15px;
    line-height: 1.7; } }

@media (max-width: 880px) {
  .trial__content {
    grid-column: 1/-1; }
  .studioSubsection__age--ageCount,
  .studioSubsection__age--priceCount {
    flex-direction: column; }
  .artServisesList {
    max-width: 100%;
    grid-column: 2/span 6; } }

@media (max-width: 840px) {
  .contacts__inform .contacts__contactsItemWrapper {
    flex-direction: row; }
  .contacts__address {
    top: 0; }
  .contacts__phones {
    margin-right: 80px; }
  .contacts__inform {
    grid-column: 2/span 5; }
  .contacts__subscribe {
    grid-column: 7/span 5; }
  .contacts__social {
    grid-row: 4;
    grid-column: 1/-1;
    justify-self: center; }
  #contacts .prefooter-contact-form {
    top: -175px; }
  .aboutUs__imgWrapper {
    grid-column: 8/-1; } }

@media (max-height: 800px) {
  .questionnaire-popup {
    overflow-y: auto; }
  .questionnaire-popup .form-wrapper.open {
    transform: translateY(15vh);
    margin-bottom: 15vh; } }

@media (max-width: 800px) {
  .ourWorkshop__button-wrapper {
    flex-direction: column; } }

@media (max-width: 785px) {
  .trial__content {
    grid-column: 1/-1; }
  #trial_les_form_id form p {
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    display: flex;
    margin-left: auto;
    margin-right: auto; }
  @-moz-document url-prefix() {
    #trial_les_form_id form p span {
      margin-bottom: 20px; } }
  .trial__title {
    font-size: 24px; }
  .trial__description {
    font-size: 13px; }
  .artSubsection .trialRegister__description {
    max-width: 100%; }
  .artSubsection__title,
  .artSubsection__description,
  .trialRegister__wrapper {
    grid-column: 1/-1; }
  .artSubsection .breadCrumbs,
  .artServisesList {
    grid-column: 1/span 7; }
  #trial_les_form_id form p input {
    height: 44px; }
  #trial_les_form_id form p span {
    margin-bottom: 1.8rem; }
  #trial_les_form_id form p span,
  #trial_les_form_id form p input[type='submit'] {
    margin-right: 5px;
    margin-left: 5px; }
  #trial_les_form_id form p input[type='submit'] {
    padding: 0;
    width: auto;
    padding-left: 6rem;
    padding-right: 6rem; } }

@media (max-width: 768px) {
  html {
    font-size: 36%; }
  .aboutUsMedia .slick-initialized .slick-slide {
    grid-template-rows: 94rem; }
  .rules__list {
    grid-column: 3/-2; }
  .studioSubsection__body {
    grid-column: 1/-1; }
  .manualsMedia__item,
  .costumesMedia__item {
    grid-template-columns: 100%;
    grid-template-rows: 40rem 45rem;
    grid-row-gap: 10px;
    background-color: #fff; }
  .manualsMedia {
    top: -350px; }
  .manualsMedia__slider .anualsMedia__slider--image {
    height: 100%;
    margin-left: auto;
    margin-right: auto; }
  .manualsMedia__slider .slick-track,
  .costumesMedia__slider .slick-track {
    height: 100%; }
  .manualsMedia__slider .slick-list.draggable {
    height: 100%;
    width: 100%; }
  .manualsMedia__slider .slick-dots {
    bottom: 0; }
  .schedule-price-section__groupe--item {
    width: calc(100%/12 * 5 - 30px); }
  .schedule-price-section__groupe--transition-wrapper {
    justify-content: center; }
  .costumesMedia__slider .slick-list {
    height: 100%; }
  .costumesMedia__slider .slick-list img {
    object-fit: contain; }
  .studioSubsection__header--content {
    grid-column: 1/span 7; }
  .studioSubsection__age,
  .studioSubsection__price {
    width: 50%;
    font-size: 14px; }
  .studioSubsection__age--title,
  .studioSubsection__price--title,
  .studioSubsection__office--title {
    font-size: 14px;
    white-space: nowrap; }
  .studioSubsection__age--ageCount,
  .studioSubsection__age--priceCount {
    font-size: 32px; }
  .studioSubsection__age--text,
  .studioSubsection__price--currency {
    font-size: 12px; }
  .studioSubsection__footer--orderDescription,
  .studioSubsection__footer--pricesDescription {
    font-size: 14px; }
  .studioSubsection .wpcf7-form-control-wrap input {
    font-size: 14px; }
  .studioSubsection__footer--order,
  .studioSubsection__footer--prices {
    padding-left: 20px; }
  .ourServises__description {
    font-weight: 400; }
  .news__postDate {
    font-size: 14px; }
  .mainSlider .tag {
    font-size: 12px; }
  .mainSlider .tag {
    top: 30px; }
  .text-wrapper .button {
    bottom: 30px; }
  .mainSlider .title {
    margin-top: 80px; }
  .artSubsection__description {
    font-size: 15px;
    line-height: 1.7; }
  .artSubsection .trialRegister__description {
    font-size: 14px;
    line-height: 1.7; }
  .servicesAge {
    font-size: 15px;
    font-weight: 400; }
  .costumesMedia__description {
    padding: 3.5rem; }
  .costumesMedia__item--description {
    font-size: 14px;
    line-height: 1.5; }
  .gallery__itemDescription {
    font-size: 14px; }
  .aboutUs__subtitle {
    font-size: 16px;
    padding-top: 12rem; }
  .aboutUs__description {
    padding-bottom: 10.5rem;
    font-size: 15px;
    line-height: 1.7; }
  .aboutUs__imgWrapper {
    grid-column: 7/-1; }
  .aboutUsMedia__label {
    font-size: 14px; }
  .blog__itemPostDate {
    font-size: 14px; }
  .backLink {
    font-size: 16px; }
  .blogArticle__content {
    font-size: 15px;
    line-height: 1.7; }
  .blogArticle {
    padding-bottom: 36rem; }
  .contacts-separate__section--inform-location {
    font-size: 12px; }
  .blog__category {
    font-size: 12px; }
  .manuals__description {
    font-size: 15px;
    line-height: 1.7; }
  .manualsMedia__item--description {
    font-size: 14px;
    line-height: 1.5; }
  .schedule__title {
    font-size: 12px; }
  .schedule__points {
    font-size: 22px; }
  .age {
    font-size: 28px; }
  .price-wrapper .price {
    font-size: 26px; }
  .schedule-price-section__groupe--item {
    padding: 3.5rem 3rem 8rem 3rem; }
  .order {
    bottom: -4.3rem; }
  #contacts .prefooter-contact-form {
    width: 90vw; } }

@media (max-width: 720px) {
  .studioSubsection__imageBg {
    display: none; }
  .reviewsSlider .description {
    line-height: 28px;
    font-size: 18px; }
  .news__moreNews {
    margin-top: 30px; }
  .news__leftBox,
  .news__rightBox {
    grid-column: 1/-1; }
  .news__rightBox {
    margin-top: 30px;
    padding-left: 0; }
  .news__rightBox iframe {
    margin-bottom: 40px;
    max-width: 340px;
    display: block;
    margin-left: auto !important;
    margin-right: auto !important; }
  .studioSubsection__header--content {
    grid-column: 1/-1; }
  .aboutUsMedia .slick-initialized .slick-slide {
    grid-template-columns: 1fr;
    grid-template-rows: 80rem; }
  .aboutUsMedia__slider {
    grid-row: 2;
    height: 215px;
    margin-top: -140px; }
  .aboutUsMedia__content {
    grid-row: 1; }
  .aboutUsMedia__content {
    padding-top: 0; }
  .aboutUsMedia .slick-dots {
    left: 0;
    width: 100%;
    justify-content: center;
    height: auto;
    bottom: calc(215px + 15px); } }

@media (max-width: 700px) {
  footer {
    font-size: 14px; }
  #footer .container-small {
    padding-top: 15px;
    padding-bottom: 15px;
    flex-direction: column;
    align-items: flex-start; }
  #menu-footer-menu {
    margin-left: -45px; }
  .vacancy {
    max-width: calc(100%/12 * 12 - 0px); } }

@media (max-width: 650px) {
  .reviewsSlider .description {
    line-height: 26px;
    font-size: 16px; }
  .prefooter-contact-form__input-wrapper-left {
    margin-right: 0; }
  .prefooter-contact-form__input-wrapper {
    flex-direction: column; }
  #contacts .prefooter-contact-form {
    padding-bottom: 40px; }
  .mainSlider .item {
    height: 390px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    padding-top: 30px; }
  .mainSlider .description {
    display: none; }
  .mainSlider .item img {
    align-self: center;
    object-position: center bottom;
    margin-left: auto;
    margin-right: -10px;
    position: relative;
    height: 170%;
    top: -10px;
    z-index: -1; }
  .text-wrapper .button {
    position: absolute;
    z-index: 2;
    bottom: -40px;
    top: auto; }
  .artServisesList {
    margin-bottom: 0; }
  .artSubsection__bgImage {
    grid-column: 1/-1;
    grid-row: 5;
    justify-self: center;
    max-width: 280px;
    margin-top: -30px; }
  #trial_les_form_id form p {
    flex-direction: column;
    margin-left: 0;
    max-width: 400px; }
  #trial_les_form_id form p input[type='submit'] {
    margin-right: auto; }
  .aboutUs__subtitle {
    padding-top: 4.5rem; }
  #aboutUs .container {
    grid-template-rows: min-content 290px;
    background: #8858A0; }
  .aboutUs__title {
    margin-left: 0; }
  .aboutUs__description {
    padding-bottom: 8rem; }
  .aboutUs__content {
    grid-column: 1/-1; }
  .aboutUs__bg {
    height: 325px;
    object-fit: contain;
    object-position: bottom;
    position: absolute;
    bottom: 0; }
  .aboutUs__imgWrapper {
    grid-column: 1/-1;
    grid-row: 2;
    margin-left: -20px;
    margin-right: -20px;
    background: #ffc40c;
    display: flex;
    justify-content: center;
    height: 23rem;
    position: relative; }
  .aboutUsMedia .slick-initialized .slick-slide {
    grid-template-rows: 90rem; }
  .prefooter-contact-form div[role='alert'] {
    bottom: 30px;
    text-align: center;
    width: 80vw;
    padding: 0;
    margin: 0;
    left: 50%;
    transform: translateX(-50%); }
  .artServisesList {
    grid-column: 1/-1; }
  .mainSlider .item {
    height: 350px; }
  .mainSlider .tag {
    top: 0; }
  .mainSlider .title {
    margin-top: 50px; }
  #contacts {
    padding-top: 486px; }
  #contacts .prefooter-contact-form {
    top: -195px; }
  .contacts__inform {
    grid-column: 1/-1;
    justify-self: left; }
  .contacts__subscribe {
    grid-column: 1/-1;
    justify-self: left; }
  .contacts__social {
    grid-column: 1/-1;
    justify-self: left; } }

@media (max-width: 610px) {
  .schedule-price-section__groupe--item {
    width: calc(100%/12 * 7 - 30px); }
  .schedule-price-section__groupe--title {
    padding-top: 4rem; }
  .container-grid.filter {
    display: block; }
  .filter-item.icon {
    display: none; }
  .schedule-price-section__header {
    padding-bottom: 3rem; }
  .schedule-price-section__subtitle {
    margin-bottom: 0;
    padding: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
  .schedule-price-section__subtitle.arrow-down .arrow_dropdown {
    transform: rotate(-90deg); }
  .arrow_dropdown {
    display: block; }
  .filter-list {
    height: auto;
    max-height: 0;
    overflow: hidden; }
  .filter-list.open {
    max-height: 100%;
    flex-wrap: wrap; }
  .filter-item {
    flex-direction: row;
    flex-basis: 40%;
    margin-bottom: 3rem;
    padding-left: 5rem; }
  .filter-item:nth-child(1) {
    flex-basis: 100%; }
  .filter-item label {
    position: static;
    text-align: left; }
  .filter-item label::after {
    left: 3rem;
    top: 0; }
  .mins {
    color: #fff; }
  .footer__boxRight {
    margin-bottom: 5px;
    font-size: 14px; }
  .footer__boxLeft {
    font-size: 12px; } }

@media (max-width: 550px) {
  .preparation__grid {
    display: flex;
    flex-direction: column;
    text-align: justify; }
  .preparation__right {
    padding: 0; }
  .footer__boxLeft {
    width: 100%; }
  .footer__boxRight {
    width: 100%; } }

@media (max-width: 510px) {
  .ourServises__grid {
    grid-template-columns: repeat(auto-fill, minmax(218px, 350px));
    grid-auto-rows: minmax(min-content, 265px); }
  .ourServises__item > img {
    height: 100%;
    width: auto;
    object-fit: contain;
    object-position: bottom; }
  #easy-newsletter-form .input-field:first-child {
    flex-grow: 1;
    position: relative; }
  #easy-newsletter-form .input-field:first-child input {
    width: 100%; }
  #trial {
    text-align: left;
    padding-top: 25px;
    padding-bottom: 0; }
  .trial__description,
  .trial__title {
    margin-left: 0; }
  #trial_les_form_id form p {
    margin-left: 0;
    max-width: 100%; }
  #trial_les_form_id form p span {
    height: 44px; }
    #trial_les_form_id form p span.wpcf7-form-control-wrap {
      width: 100%; }
  #trial_les_form_id form p input {
    padding: 0;
    padding-left: 20px;
    border-radius: 0;
    font-size: 14px; }
  #trial_les_form_id form p input[type='submit'] {
    border-radius: 27.5px;
    padding: 11px 35px;
    width: auto;
    margin-right: auto; }
  #trial_les_form_id div.wpcf7-validation-errors {
    margin-top: -25px; }
  .ourWorkshop__itemDescription {
    font-size: 14px; }
  .contacts__itemTitle {
    font-size: 16px; }
  .contacts__contactsItemWrapper {
    font-size: 14px; }
  #contacts {
    padding-bottom: 20px; }
  .ourWorkshop__itemDescription {
    line-height: 20px; }
  #trial_les_form_id form p {
    justify-content: flex-start; }
  .trialRegister {
    padding-bottom: 0; }
  .spesial__item {
    width: calc(100%/12 * 8 - 30px); }
  #trial_les_form_id span.wpcf7-not-valid-tip {
    height: auto; }
  .manualsMedia__item,
  .costumesMedia__item {
    grid-template-rows: 40rem 45rem; }
  #trial_les_form_id div.wpcf7-validation-errors,
  div.wpcf7-mail-sent-ok {
    border: none;
    font-size: 11px;
    position: relative;
    bottom: 30px;
    margin: 0; }
  .modal__body {
    padding: 2rem 5rem 3rem 5rem; }
  .modal__inform--wrapper,
  .exp-salary-container {
    flex-direction: column; }
  .modal__inform--wrapper span,
  .exp-salary-container span {
    width: 100%; }
  .questionnaire-popup div.wpcf7-validation-errors {
    width: calc(100% - 8rem);
    bottom: 3.5rem; }
  .contacts-separate__section-wrapper {
    flex-direction: column; }
  .contacts-separate__title {
    padding-bottom: 5rem;
    padding-top: 5rem; }
  .contacts-separate__section:not(:last-child) {
    margin-bottom: 2rem;
    width: 100%; }
  .spesial__title {
    font-size: 3rem; }
  .spesial__item {
    width: 100%;
    height: 40rem; } }

@media (max-width: 460px) {
  .reviewsSlider .description {
    line-height: 19px;
    font-size: 14px; }
  .text-wrapper .button {
    bottom: -130px; }
  .reviewsSlider .title {
    margin-top: -20px;
    font-size: 16px; }
  .reviewsSlider .description::after {
    bottom: 60px; }
  .reviewsSlider .slick-dots li {
    margin: 0; }
  .contacts__phones {
    margin-right: 0; }
  footer {
    font-size: 12px; }
  .aboutUsMedia .slick-initialized .slick-slide {
    grid-template-rows: 95rem; } }

@media (max-width: 425px) {
  .ourServises__grid {
    grid-auto-rows: minmax(min-content, 230px); }
  .contacts__inform .contacts__contactsItemWrapper {
    font-size: 12px; }
  .prefooter-contact-form__title {
    padding-bottom: 15px; }
  #contacts .prefooter-contact-form {
    padding: 30px 20px 40px 20px; }
  #contacts .prefooter-contact-form {
    padding-bottom: 40px; }
  #contacts .prefooter-contact-form input {
    font-size: 14px; }
  #contacts .prefooter-contact-form textarea {
    height: 125px; }
  .aboutUsMedia .slick-initialized .slick-slide {
    grid-template-rows: 104rem; }
  .prefooter-contact-form__input-wrapper .your-comment span[role='alert'] {
    top: -127px; }
  .manualsMedia__description {
    padding: 2rem; }
  .manualsMedia__item,
  .costumesMedia__item {
    grid-template-rows: 35rem 65rem; }
  .manualsMedia__slider .slick-dots {
    bottom: -11px; }
  .schedule-price-section__groupe--item {
    width: calc(100%/12 * 10 - 30px); }
  .costumesMedia__item--button {
    bottom: 2rem; }
  #aboutUs .container {
    grid-template-rows: min-content 260px; }
  #contacts {
    padding-top: 400px; } }

@media (max-width: 375px) {
  .manualsMedia__slider .slick-next {
    right: -3.5rem; }
  .manualsMedia__slider .slick-prev {
    left: -3.5rem; }
  .manualsMedia__slider {
    padding: 2rem 2rem; }
  .manualsMedia__item,
  .costumesMedia__item {
    grid-template-rows: 35rem 70rem; }
  #contacts {
    padding-top: 315px; }
  #contacts .prefooter-contact-form {
    top: -280px; } }

@media (max-width: 350px) {
  .contacts__inform .contacts__contactsItemWrapper {
    font-size: 10px;
    line-height: 20px; }
  .aboutUs__description {
    padding-bottom: 0; }
  .aboutUsMedia .slick-initialized .slick-slide {
    grid-template-rows: 105rem; }
  .manualsMedia__item,
  .costumesMedia__item {
    grid-template-rows: 30rem 65rem; }
  .mainSlider .text-wrapper {
    position: static; }
  .text-wrapper .button {
    position: absolute;
    bottom: 30px;
    left: 30px; }
  .mainSlider .tag {
    margin-left: 30px;
    top: 30px; }
  .manualsMedia__item--description {
    line-height: 22px;
    height: 198px;
    overflow: hidden; } }

@media (max-width: 320px) {
  .fb-widget {
    width: 280px; } }

.schedule-price-section__metagroup {
  display: flex;
  width: 100%;
  border-radius: 4px 0 0 4px; }
  .schedule-price-section__metagroup:not(:last-of-type) {
    margin-bottom: 25px; }
  .schedule-price-section__metagroup.education .metagroup__name {
    background: linear-gradient(90deg, #5ACA14 0%, #4CB00C 100%); }
  .schedule-price-section__metagroup.art .metagroup__name {
    background: linear-gradient(90deg, #00B0FF 0%, #0199DD 100%); }
  .schedule-price-section__metagroup.preschool .metagroup__name {
    background: linear-gradient(90deg, #FA5129 0%, #E04722 100%); }
  .schedule-price-section__metagroup.individual .metagroup__name {
    background: linear-gradient(90deg, #F18C0C 0%, #DA800C 100%); }
  .schedule-price-section__metagroup.courses .metagroup__name {
    background: linear-gradient(90deg, #F18C0C 0%, #DA800C 100%); }
  .schedule-price-section__metagroup.costumes .metagroup__name {
    background: linear-gradient(90deg, #07C394 0%, #06B086 100%); }
  @media only screen and (max-width: 1280px) {
    .schedule-price-section__metagroup {
      flex-direction: column; } }

.metagroup__name {
  min-width: 168px;
  font-family: Roboto;
  font-size: 40px;
  font-weight: bold;
  line-height: 47px;
  text-align: center;
  color: #fff;
  padding: 44px 0;
  border-radius: 4px 0 0 4px;
  display: flex;
  flex-direction: column; }
  @media only screen and (max-width: 1280px) {
    .metagroup__name {
      border-radius: 4px 4px 0 0; } }

.metagroup__content {
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
  padding-left: 74px;
  background: #FAFAFA;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-left: none; }
  @media only screen and (max-width: 1280px) {
    .metagroup__content {
      padding-left: 0; } }

.metagroup__header {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 30px; }
  @media only screen and (max-width: 1280px) {
    .metagroup__header {
      display: none; } }
  .metagroup__header_mobile {
    display: none; }
    @media only screen and (max-width: 1280px) {
      .metagroup__header_mobile {
        display: flex;
        flex-direction: column;
        padding-top: 0;
        min-width: 250px;
        border-bottom: none; } }
    @media only screen and (max-width: 650px) {
      .metagroup__header_mobile {
        min-width: 100px; } }

.metagroup__span_big {
  text-align: center; }

.metagroup__span_medium {
  font-size: 2.52rem;
  line-height: 5.67rem; }

.metagroup__span_small {
  font-size: 14px;
  line-height: 16px;
  font-weight: normal; }

.schedule-price-section__groupe a {
  display: block;
  transition: color .3s ease-in;
  text-align: left;
  padding-left: 0;
  margin-left: 0;
  font-size: 3.06rem;
  line-height: 3.87rem; }
  .schedule-price-section__groupe a:hover {
    color: #8858A0; }

.schedule-price-section__groupe--transition-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0;
  margin-left: 0; }

.schedule-price-section__groupe--item {
  width: 100%;
  margin-right: 15px;
  border-radius: 0 4px 4px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative; }
  .schedule-price-section__groupe--item:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
  @media only screen and (max-width: 1280px) {
    .schedule-price-section__groupe--item {
      align-items: flex-start;
      padding: 0 20px;
      padding-bottom: 23px;
      border-left: 1px solid rgba(0, 0, 0, 0.15); } }

.schedule-price-section__groupe--title {
  font-size: 3.4em;
  font-family: "Lora", sans-serif;
  color: #2C2C2C;
  font-weight: bold;
  padding-top: 5rem;
  padding-bottom: 3rem;
  text-align: center; }

.schedule-price-section__groupe:first-child .schedule-price-section__groupe--title {
  padding-top: 6rem; }

.schedule-content {
  display: flex;
  flex-direction: row;
  width: 100%; }
  @media only screen and (max-width: 1280px) {
    .schedule-content {
      flex-direction: column; } }

.schedule-wrapper {
  margin: 2.16rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .schedule-wrapper svg, .schedule-wrapper svg path {
    height: 20px;
    width: 20px;
    margin-top: -3px; }
  .schedule-wrapper.office-wrapper {
    flex-shrink: 2;
    min-width: 30px; }
  @media only screen and (max-width: 1280px) {
    .schedule-wrapper {
      height: 52px;
      margin-top: 0;
      margin-bottom: 0; }
      .schedule-wrapper:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
      .schedule-wrapper.office-wrapper {
        border-bottom: none;
        padding-top: 10px;
        margin-bottom: 20px; }
      .schedule-wrapper.button-wrapper {
        align-items: flex-end; } }

.schedule__title {
  display: flex;
  align-items: center;
  color: black;
  font-size: 1.26rem;
  line-height: 1.44rem;
  font-weight: 500;
  margin-bottom: 10px; }
  .schedule__title svg {
    margin-right: 5px;
    opacity: .5; }
  .schedule__title.office-title {
    flex-shrink: 2; }
  @media only screen and (max-width: 1280px) {
    .schedule__title {
      font-size: 16px;
      line-height: 18px; } }
  @media only screen and (max-width: 800px) {
    .schedule__title {
      font-size: 14px;
      line-height: 16px; } }
  @media only screen and (max-width: 500px) {
    .schedule__title {
      font-size: 12px;
      line-height: 14px; } }

.schedule__points {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  font-size: 2.52rem;
  line-height: 2.97rem; }
  @media only screen and (max-width: 1280px) {
    .schedule__points {
      justify-content: flex-end; } }
  @media only screen and (max-width: 514px) {
    .schedule__points {
      font-size: 16px; } }

.schedule__column {
  width: 100%;
  display: flex; }
  .schedule__column svg {
    max-width: 24px; }
  @media only screen and (max-width: 1280px) {
    .schedule__column {
      height: 52px;
      margin-bottom: 0; }
      .schedule__column:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
      .schedule__column.office-title {
        border-bottom: none; } }

.schedule-button.order {
  background: #E8E8E8;
  box-shadow: none;
  color: #212121;
  font-size: 1.53rem;
  border: 1px solid rgba(0, 0, 0, 0.15); }
  .schedule-button.order:hover {
    background: #8858A0;
    color: #fff; }
  @media only screen and (max-width: 1280px) {
    .schedule-button.order {
      background: #ffc40c;
      border-color: #ffc40c;
      width: 312px;
      font-size: 15px;
      line-height: 11px; } }
  @media only screen and (max-width: 514px) {
    .schedule-button.order {
      width: 270px; } }
  @media only screen and (max-width: 460px) {
    .schedule-button.order {
      width: 180px; } }

.order {
  color: #212121;
  background: #f0f0f0;
  transition: all .1s ease;
  box-shadow: none;
  border: 1px solid #dcdcdc; }

.time-wrapper {
  display: flex;
  margin-right: 12px;
  margin-bottom: 0.5rem; }
  .time-wrapper:first-of-type {
    margin-left: 0; }
  .time-wrapper:last-of-type {
    margin-right: 0; }
  .time-wrapper .mins {
    color: #424242;
    font-size: 1.17rem;
    line-height: 1.35rem;
    text-decoration: underline;
    margin-left: 3px; }
  .time-wrapper .coma {
    display: inline-block;
    margin-left: 2px; }

.price-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: #424242; }
  .price-wrapper:not(:last-of-type) {
    margin-right: 50px; }
    @media only screen and (max-width: 1280px) {
      .price-wrapper:not(:last-of-type) {
        margin-right: 28px; } }
  .price-wrapper .price {
    font-size: 2.52rem;
    font-weight: 900; }
    @media only screen and (max-width: 514px) {
      .price-wrapper .price {
        font-size: 16px; } }
  .price-wrapper .desc {
    color: rgba(0, 0, 0, 0.5);
    font-size: 1.26rem;
    line-height: 1.44rem; }
    @media only screen and (max-width: 514px) {
      .price-wrapper .desc {
        font-size: 10px; } }
  .price-wrapper:nth-child(even) .price {
    font-weight: normal; }

.days-connection {
  color: rgba(66, 66, 66, 0.8);
  font-size: 1.8rem;
  font-weight: 300;
  margin-right: 5px;
  margin-left: 5px; }

.schedule__points--price {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-right: -3rem;
  margin-left: -3rem; }
  @media only screen and (max-width: 1280px) {
    .schedule__points--price {
      justify-content: flex-end; } }

.schedule__points--office .office-wrapper {
  display: flex;
  justify-content: center; }

.schedule__points--office .office-logo svg {
  height: 38px;
  width: 28px; }

@media only screen and (max-width: 1280px) {
  .schedule__points--office .office-logo {
    margin-right: 10px; } }

.schedule__points--office .office-address_mobile {
  display: none; }
  @media only screen and (max-width: 1280px) {
    .schedule__points--office .office-address_mobile {
      display: flex;
      font-size: 10px;
      line-height: 6px; } }

@media only screen and (max-width: 1280px) {
  .schedule__points--office {
    align-items: center; } }

.office-select.v-select.single {
  min-width: 320px;
  padding-top: 40px; }
  .office-select.v-select.single .dropdown-toggle {
    border: none;
    border-radius: 0px;
    border-bottom: 2px solid #fff; }
  .office-select.v-select.single .selected-tag {
    color: #fff;
    font-size: 1.89rem;
    line-height: 2.16rem;
    font-weight: 500;
    font-family: Roboto; }
  .office-select.v-select.single .clear {
    display: none; }
  .office-select.v-select.single .open-indicator::before {
    border-color: #fff; }
  .office-select.v-select.single .dropdown-menu {
    overflow: hidden;
    background: #fff;
    font-size: 18px;
    font-weight: normal;
    font-family: Roboto;
    border-radius: 0px;
    padding-bottom: 0;
    padding-top: 0;
    border: none; }
    .office-select.v-select.single .dropdown-menu li a {
      padding: 10px 25px;
      display: flex;
      align-items: center;
      line-height: 18px;
      padding-left: 0; }
      .office-select.v-select.single .dropdown-menu li a:hover {
        background-color: #ffc40c;
        color: #000; }
    .office-select.v-select.single .dropdown-menu li.active a {
      background-color: rgba(0, 0, 0, 0.15);
      color: #000; }
    .office-select.v-select.single .dropdown-menu li.highlight a {
      background-color: #ffc40c;
      color: #000; }
  .office-select.v-select.single.open .selected-tag {
    opacity: 1;
    position: relative; }
  .office-select.v-select.single.open .dropdown-toggle {
    border-color: transparent; }
  .office-select.v-select.single .option {
    display: flex;
    align-items: center; }
    .office-select.v-select.single .option__icon {
      width: 15px;
      margin-right: 10px;
      margin-left: 10px; }
      .office-select.v-select.single .option__icon_noicon {
        width: 35px; }
  @media only screen and (max-width: 1365px) {
    .office-select.v-select.single {
      padding-top: 25px; } }
  @media only screen and (max-width: 1200px) {
    .office-select.v-select.single {
      padding-top: 25px; } }
  @media only screen and (max-width: 992px) {
    .office-select.v-select.single {
      padding-top: 20px;
      min-width: 220px; } }
  @media only screen and (max-width: 680px) {
    .office-select.v-select.single {
      min-width: 150px; } }

.schedule-price-section__body {
  padding-bottom: 60px; }
  @media only screen and (max-width: 992px) {
    .schedule-price-section__body {
      padding-bottom: 250px; } }

.schedule-price-section__subtitle {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 5rem;
  text-align: flex-start; }
  @media only screen and (max-width: 840px) {
    .schedule-price-section__subtitle {
      margin-bottom: 10px; } }

@media only screen and (max-width: 610px) {
  .schedule-price-section__content_small .schedule-price-section__subtitle {
    display: none; } }

@media only screen and (max-width: 610px) {
  .filter-list.open {
    padding-top: 20px; } }

.filter-item label {
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
  position: absolute;
  font-size: 2.16rem;
  line-height: 2.52rem; }
  .filter-item label::after {
    content: '';
    display: block;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.3);
    background: #8858A0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }
    @media only screen and (max-width: 610px) {
      .filter-item label::after {
        left: 3rem; } }
  @media only screen and (max-width: 610px) {
    .filter-item label {
      position: static;
      text-align: left; } }

.filter-item #schedule-price__all + label, .filter-item #schedule-price__old + label {
  font-size: 1.89rem;
  line-height: 2.16rem; }

@media only screen and (max-width: 610px) {
  .filter-item {
    flex-direction: row; } }

.filter-item img {
  opacity: .3;
  height: 100%; }

.filter-item input {
  cursor: pointer;
  opacity: 0; }

.filter-item input[type=checkbox]:checked + label::after {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
  border: 3px solid #FFFFFF;
  background-color: #FFC40C; }

.filter-item.checked label::after {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
  border: 3px solid #FFFFFF;
  background-color: #FFC40C; }

.filter-item input[type=checkbox]:checked + label {
  color: white; }

.filter-item:hover {
  color: white; }

.filter-item:hover label::after {
  border-color: white; }

.filter.stick {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  padding-bottom: 10px;
  padding-top: 10px;
  background: #8858A0;
  z-index: 1;
  padding-left: 24vw;
  padding-right: 24vw;
  transition: top .5s linear;
  width: 100%;
  max-width: 100%;
  padding: 7px 15% !important; }
  .filter.stick .schedule-price-section__subtitle {
    display: none; }
  .filter.stick .filter-list {
    height: 6rem; }
  .filter.stick .office-select {
    padding-top: 15px; }

.filter.stick label {
  font-size: 18px; }

.filter.stick label::after {
  height: 20px;
  width: 20px; }

.filter.stick.offsetTop {
  top: 100px; }

.schedule-price-section__content_large {
  grid-column: 1/9; }

.schedule-price-section__content_small {
  grid-column: 10/-1; }

@media only screen and (max-width: 1550px) {
  .schedule-price-section__content_large {
    margin-left: 15px; }
  .schedule-price-section__content_small {
    margin-right: 15px; } }

@media only screen and (max-width: 1365px) {
  .schedule-price-section__content_large {
    grid-column: 3/8;
    margin-left: 0; }
  .schedule-price-section__content_small {
    grid-column: 9/10;
    margin-right: 0; } }

@media only screen and (max-width: 1200px) {
  .schedule-price-section__content_large {
    grid-column: 2/8; }
  .schedule-price-section__content_small {
    grid-column: 9/11; } }

@media only screen and (max-width: 992px) {
  .schedule-price-section__content_large {
    grid-column: 1/8;
    margin-left: 15px; }
  .schedule-price-section__content_small {
    grid-column: 9/-1;
    margin-right: 15px; } }

@media only screen and (max-width: 840px) {
  .schedule-price-section__content_large {
    grid-column: 1/-1;
    margin-left: 15px;
    margin-right: 15px; }
  .schedule-price-section__content_small {
    grid-column: 1/7;
    margin-right: 0;
    margin-left: 15px;
    margin-top: 50px; } }

@media only screen and (max-width: 670px) {
  .schedule-price-section__content_small {
    grid-column: 1/8; } }

@media only screen and (max-width: 610px) {
  .schedule-price-section__content_large {
    grid-column: 2/-2;
    margin-left: 15px;
    margin-right: 15px; }
  .schedule-price-section__content_small {
    grid-column: 2/-2;
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 0px; } }

@media only screen and (max-width: 1550px) {
  .schedule-price-section__body .container {
    max-width: 100%; } }

.contacts-separate {
  margin-top: 100px;
  padding-bottom: 8rem; }

.contacts-separate__title {
  text-align: center;
  font-family: "Lora", sans-serif;
  color: #212121;
  font-size: 5rem;
  padding-top: 15rem;
  padding-bottom: 10rem; }
  @media only screen and (max-width: 576px) {
    .contacts-separate__title {
      padding-top: 65px;
      padding-bottom: 50px; } }

.contacts-separate__cards-wrapper {
  display: flex;
  margin-bottom: 280px; }
  @media only screen and (max-width: 920px) {
    .contacts-separate__cards-wrapper {
      flex-direction: column; } }

.contacts-separate__section-wrapper {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  padding: 60px 120px; }
  .contacts-separate__section-wrapper:last-of-type {
    background-color: #f5f5f5; }
  @media only screen and (max-width: 1365px) {
    .contacts-separate__section-wrapper {
      padding: 40px 80px; } }
  @media only screen and (max-width: 1023px) {
    .contacts-separate__section-wrapper {
      padding: 30px 65px; } }
  @media only screen and (max-width: 920px) {
    .contacts-separate__section-wrapper {
      padding: 10px 15px; } }

.contacts-separate__header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  margin-left: -50px; }
  .contacts-separate__header-icon {
    margin-right: 15px; }
  .contacts-separate__header-text {
    color: #212121;
    font-size: 2.34rem;
    line-height: 3.51rem;
    font-family: Lora,Roboto;
    font-weight: bold; }
  @media only screen and (max-width: 920px) {
    .contacts-separate__header {
      margin-left: 0;
      margin-bottom: 25px; } }

.contacts-separate__section {
  display: flex;
  margin-bottom: 25px; }
  .contacts-separate__section_schedule {
    flex-direction: column;
    margin-left: 10px; }
  @media only screen and (max-width: 920px) {
    .contacts-separate__section {
      margin-bottom: 10px; } }

.contacts-separate__section--title {
  opacity: 0.8;
  font-size: 14px;
  line-height: 2.8rem;
  margin-bottom: 2rem;
  position: relative; }

.contacts-separate__section--title svg,
.contacts-separate__section--title svg path {
  fill: #8858A0;
  margin-right: 0.8rem;
  height: 36px;
  width: 36px; }

.contacts-separate__section--link {
  color: #2C2C2C;
  font-size: 1.62rem;
  line-height: 2.52rem;
  font-family: Roboto;
  padding-top: 4px; }
  @media only screen and (max-width: 920px) {
    .contacts-separate__section--link {
      font-size: 14px;
      line-height: 28px; } }

.contacts-separate__section--helper-wrapper {
  display: flex;
  flex-direction: column; }

.contacts-separate__section--inform-location {
  opacity: 0.8;
  color: #888888;
  font-size: 1.4rem;
  line-height: 2.8rem;
  margin-top: 0.1rem; }

.schedule {
  background: #8858A0;
  padding-top: 7rem;
  padding-bottom: 35rem; }

.schedule__sections--wrapper {
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.schedule__sections--day {
  color: #2c2c2c;
  font-size: 1.62rem;
  line-height: 2.52rem;
  font-weight: 100; }
  @media only screen and (max-width: 920px) {
    .schedule__sections--day {
      font-size: 14px;
      line-height: 28px; } }

.schedule__sections-hours {
  color: #2c2c2c;
  font-size: 2.016rem;
  line-height: 2.34rem;
  font-weight: bold;
  display: flex; }
  @media only screen and (max-width: 920px) {
    .schedule__sections-hours {
      font-size: 16px;
      line-height: 19px; } }

.schedule__sections-hours span {
  font-size: 0.864rem;
  line-height: 0.99rem; }

.schedule__sections {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0; }
  .schedule__sections:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); }

#schedule-price-section {
  margin-top: 100px; }

.schedule-price-section__header {
  background: #8858A0;
  color: #fff;
  padding-bottom: 6rem; }

.schedule-price-section__title {
  font-size: 5rem;
  font-family: "Lora", sans-serif;
  font-weight: bold;
  text-align: center;
  padding-top: 10rem;
  padding-bottom: 10rem; }

.captha-group {
  display: flex;
  flex-direction: row;
  position: relative; }
  .captha-group span {
    width: 100%; }
    .captha-group span[role="alert"] {
      top: -70px; }
  .captha-group__capha {
    background-color: #2c2c2c;
    display: flex;
    align-items: center;
    border-radius: 4px 0 0 4px; }
  .captha-group__input {
    width: 100%; }
    .captha-group__input-core {
      width: 100%;
      display: flex;
      flex-grow: 1;
      border-left: none !important;
      border-radius: 0 4px 4px 0 !important;
      margin-bottom: 0 !important; }
      @media only screen and (max-width: 360px) {
        .captha-group__input-core {
          padding-left: 20px !important; } }
  @media only screen and (max-width: 650px) {
    .captha-group {
      margin-bottom: 12px; } }

a[href^="http://maps.google.com/maps"] {
  display: none !important; }

a[href^="https://maps.google.com/maps"] {
  display: none !important; }

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display: none; }

.gmnoprint div {
  background: none !important; }

.prefooter-contact-form div[role=alert] {
  bottom: 60px; }
  @media only screen and (max-width: 650px) {
    .prefooter-contact-form div[role=alert] {
      bottom: 31px; } }
